import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Reservation } from "@interfaces/reservation";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { format, isSameDay, parse, parseISO } from "date-fns";
import { Tag } from "@interfaces/tag";
import { confirm } from "@web/app/components/dialogs/confirm";
import { tr } from "@util/tr/tr";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { ReservationsService } from "@services/reservations.service";
import {
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  throwError,
  timer,
} from "rxjs";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import {
  catchError,
  debounce,
  debounceTime,
  delay,
  filter,
  first,
  map,
  pluck,
  share,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Moment } from "moment";
import * as _ from "lodash";
import { Client } from "@interfaces/client";
import { toast } from "@web/util/toast";
import { GiftsService } from "@services/dws/gifts.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { ExperienceAvailabilitiesService } from "@services/experience-availabilities.service";
import {
  ExperienceAvailability,
  ExperienceAvailabilityForDay,
} from "@interfaces/experience-availability";
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from "@angular/material/datepicker";
import { LoadingComponent } from "@web/app/components/loading/loading.component";
import { AccountsService } from "@services/accounts.service";
import { observe } from "@web/util/loading/loading";
import { Host } from "@interfaces/host";
import {
  CashRegisterService,
  ContactsService,
  ContactsTagsService,
  ExternalCalendarSyncService,
  LanguagesService,
  PaymentMethodsService,
  ReservationContactsService,
  StatisticsService,
} from "@services/dws";
import { DWS } from "@interfaces/dws";
import { locale } from "@util/locale";
import { HostReservationFormOptionsService } from "@services/host-reservation-form-options/host-reservation-form-options.service";
import { HostReservationFormOptions } from "@services/host-reservation-form-options/host-reservation-form-options";
import { ReservationDetail } from "@services/dws/scheduling/reservation-detail/reservation-detail";
import { ReservationDetailService } from "@services/dws/scheduling/reservation-detail/reservation-detail.service";
import { CurrencyPipe, getCurrencySymbol } from "@angular/common";
import { TranslateDomainPipe } from "@web/app/pipes/translate/translate-domain.pipe";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AvailabilityBlock } from "@interfaces/dws/availability-block";
import { NoticeComponent } from "@web/app/components/dialogs/notice/notice.component";
import { AvailabilityBlockService } from "@services/dws/availability-block.service";
import { ExperienceAvailabilityService } from "@services/dws/experience-availability.service";
import { TranslateService } from "@ngx-translate/core";
import { ExperienceAvailability as ExperienceAvailability2 } from "@interfaces/dws/experience-availabilities";
import { ExperiencePriceLabel } from "@interfaces/experience-price-label";
import { ExperiencePriceExtra } from "@interfaces/experience-price-extra";
import { ManualReservationRequestsListenerService } from "@services/manual-reservation-requests-listener.service";
import { ReservationComponent } from "@web/app/components/dialogs/reservation/reservation.component";
import MappedStatus = Reservation.MappedStatus;
import Company = Host.Company;
import { GiftDetailComponent } from "../dialogs/gift-detail/gift-detail.component";
import { ExperienceSlots } from "@interfaces/dws/experience-slots";
import { ReservationDTO } from "@interfaces/dws/reservation";
import { ContactAssociationComponent } from "../dialogs/contact-association/contact-association.component";
import { DWSFormOptions, ExperienceDWS } from "@interfaces/dws/experience-dws";
import { ExperienceEventsAvailabilitiesService } from "@services/dws/experience-events-availabilities.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { InfoIconHoverableComponent } from "../info-icon-hoverable/info-icon-hoverable.component";
import { Page } from "@interfaces/dws/page";
import { Contact } from "@interfaces/dws/contact";
import { PaymentLinkDialogComponent } from "../dialogs/payment-link-dialog/payment-link-dialog.component";
import { WSPaymentsService } from "@services/dws/ws-payments.service";
import { PaymentLink } from "@interfaces/dws/payment";
import { AvailabilityAutomationDialogComponent } from "../dialogs/availability-automation/availability-automation.component";
import { HostCouponsService } from "@services/host-coupons.service";
import { IntegrationSyncMetadata, IntegrationEntityTypes } from "@interfaces/integration-sync-metadata";
import { environment } from "@env/environment";

marker("Are you sure you want to save the changes?");
marker("By leaving now you will lose all the changes made to the reservation.");
marker("Save");
marker("Leave");
marker("Back");
marker("Reservation created.");
marker("Reservation updated.");
marker("There was a problem saving the reservation. Try again later.");
marker("Are you sure you want to delete the reservation?");
marker("Reservation deleted successfully.");
marker("There was a problem deleting the reservation. Try again later.");
marker("Employee");
marker("Another update operation is running");
marker("Processing. Please do not close the browser or refresh the page...");

interface EditClientRequest {
  client?: Client;
  callback: (client: Client) => void;
}

@Component({
  selector: "web-datepicker-empty-header",
  styles: [],
  template: ``,
})
export class DatePickerEmptyHeaderComponent {}

const experienceTypeCodes = ["tasting", "stay", "event", "special", "smart"];

@Component({
  selector: "app-reservation-detail",
  templateUrl: "./reservation-detail.component.html",
  styleUrls: ["./reservation-detail.component.scss"],
})
export class ReservationDetailComponent implements OnInit, OnChanges {
  emptyHeader = DatePickerEmptyHeaderComponent;
  isConfirmedReservation = false;
  tagsPopoverVisibility = false;
  reservationContactToEditId?: number | string;

  @ViewChild("trigger") trigger: any = MatAutocompleteTrigger;
  @Output() closeClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() reservationId?: string;
  reservation?: ReservationDTO;
  @Input() reservationToClone?: ReservationDTO;
  @Input() isTourOperator: boolean = false;
  @Output() addClient: EventEmitter<EditClientRequest> =
    new EventEmitter<EditClientRequest>();
  companyInt?: Host.Company;
  saveDisabled = false;
  languages: DWS.Language[] = [];
  messageError = "An error occurred. Try later or contact support team.";

  availability: { count: number; max: number } = { count: 0, max: 0 };

  contactsData: Record<
    number | string,
    {
      contactStatistics: DWS.Statistic;
      contactTags: DWS.ContactTag[];
      contactPrivacy: {
        acceptedPrivacyTerms?: boolean;
        acceptedMarketing?: boolean;
        acceptedProfiling?: boolean;
        manualMarketing?: boolean;
        unsubscribed?: boolean;
      };
    }
  > = {};
  contactDetailOpen: Record<number | string, boolean> = {};
  contactsNotMatchingAlerts: Record<
    number | string,
    { [key: string]: string }
  > = {};

  private _experience: ExperienceDWS | undefined;
  @Output()
  experienceChange: EventEmitter<ExperienceDWS> =
    new EventEmitter<ExperienceDWS>();

  manualOrigins: HostReservationFormOptions.ManualOrigin[] = [];

  @Input() byGift?: boolean;
  @Output() returnGift: EventEmitter<boolean> = new EventEmitter<boolean>();
  availabilities2: ExperienceAvailability2[] = [];

  paymentLinkData: PaymentLink | null = null;
  resCashRegisterMetadata: IntegrationSyncMetadata | undefined = undefined;
  regCashIntegrated = false;

  @Input() set experience(v: ExperienceDWS | undefined) {
    this._experience = v;
    this.experienceChange.emit(v);
    this.experienceChanged();
  }

  availableExperiences: ExperienceDWS[] = [];
  availableExperiencesActive: ExperienceDWS[] = [];
  filteredExperiences: ExperienceDWS[] = [];
  availableLanguages: HostReservationFormOptions.Language[] = [];
  availableVisitReasons: HostReservationFormOptions.VisitReason[] = [];
  availableTimes: string[] = [];
  availableSlots: ExperienceSlots[] = [];
  availabilities: ExperienceAvailabilityForDay[] = [];
  availabilitiesBlock: HostReservationFormOptions.AvailabilityBlock[] = [];

  allTags: Tag[] = [];

  loadingClients: boolean = false;

  company!: Company;
  isDraft = false;
  isDraftable = false;

  formPatched = true;

  eventDates: string[] = [];

  paymentMethods: HostReservationFormOptions.PaymentMethod[] = [];

  company$ = new ReplaySubject<Company>(1);
  rooms$ = new ReplaySubject<HostReservationFormOptions.Room[]>(1);
  rooms: HostReservationFormOptions.Room[] = [];
  employees$ = new ReplaySubject<HostReservationFormOptions.Employee[]>(1);

  language = "";

  companyId$ = new ReplaySubject<string>(1);
  companyId: string = "";
  cameFromCalendar: boolean = false;

  hasAnyAvailabilityForDateDay = false;

  hasEnabledAutomations = false;
  shouldAskForSlotClosing = true;
  isConfirmingOrRejecting = false;
  team: HostReservationFormOptions.Employee[] = [];
  countries: HostReservationFormOptions.Country[] = [];
  availableCoupons: Host.Coupon.Data[] = [];

  clients: DWS.Contact[] = [];

  clientForm!: FormGroup;
  clientSaveCallback: (client: Client) => void = () => {};
  @ViewChild("clientSideNav") clientSideNav!: MatSidenav;

  compareId = (a: { id: any }, b: { id: any }) => {
    if (!a && !b) return true;
    if ((a && !b) || (!a && b)) return false;
    return a.id == b.id;
  };

  compareExperience = this.compareId;
  compareTag = this.compareId;
  compareRoom = this.compareId;
  compareEmployee = this.compareId;
  compareManualOrigin = this.compareId;
  comparePaymentMethod = this.compareId;
  objectKeys = Object.keys;

  editable!: ReservationDetail;
  form!: FormGroup;
  clients$!: Observable<DWS.Page<DWS.Contact>>;
  masterClients$!: Observable<DWS.Page<DWS.Contact>>;

  giftAssociatedBeforeRevocation: string | undefined;
  isCreatingPaymentLink = false;

  temporaryFormValues: { [key: string]: any } = {};

  gift: DWS.Gift | undefined;

  getContactCountryIso(id: number | string) {
    return (
      this.form.get("reservation")?.get("reservationContacts") as FormArray
    )?.controls.find((c) => c.get("id")?.value == id)?.value.countryIso;
  }

  get experiencePriceLabelsFormArray(): FormArray {
    return this.form
      .get("reservation")
      ?.get("experience_price_labels") as FormArray;
  }

  get experiencePriceExtrasFormArray(): FormArray {
    return this.form
      .get("reservation")
      ?.get("experience_price_extras") as FormArray;
  }

  get paidOnline() {
    return !(
      this.reservation?.paid == null ||
      this.reservation?.paid == false ||
      this.reservation?.netTotalCents == null ||
      this.reservation?.netTotalCents == 0
    );
  }

  get formArrayPriceLabels() {
    return this.experiencePriceLabelsFormArray.controls as FormGroup[];
  }

  get formArrayPriceExtras() {
    return this.experiencePriceExtrasFormArray.controls as FormGroup[];
  }

  get experience(): ExperienceDWS | undefined {
    return this._experience;
  }
  set loading(v: boolean) {
    LoadingComponent.loading = v;
  }

  searchControl: FormControl = new FormControl();
  masterSearchControl: FormControl = new FormControl();

  get canEdit(): boolean {
    return (
      !this.reservation || this.reservation.type === "manual" || !this.completed
    );
  }

  get canEditCoreInfo(): boolean {
    return !this.reservation;
  }

  // TODO
  get canEditPaymentInfo(): boolean {
    return !this.reservation || !this.completed;
  }

  get waiting(): boolean {
    return this.reservation?.state === "waiting";
  }

  get confirmed(): boolean {
    return this.reservation?.state === "confirmed";
  }

  get completed(): boolean {
    return this.reservation?.state === "completed";
  }

  get isGift(): boolean {
    return !!this.reservation && !!this.reservation?.giftId;
  }

  get currencySymbol() {
    return (
      getCurrencySymbol(
        this.reservation?.paymentCurrency || "EUR",
        "wide",
        undefined
      ) || ("" as string)
    );
  }

  get isAvailabilityBlock() {
    const date =
      this.reservation?.date ||
      this.extractDateString(this.form.get("reservation")?.get("date")?.value);
    const time = moment(
      this.reservation?.time ||
        this.form.get("reservation")?.get("time")?.value,
      "HH:ss"
    ).format("HH:ss:mm");
    if (!this.availabilitiesBlock) return undefined;
    return this.availabilitiesBlock.find(
      (ab) => ab.date === date && ab.time === time && ab.closeSlots
    );
  }

  get isLanguageBlock() {
    const date =
      this.reservation?.date ||
      this.extractDateString(this.form.get("reservation")?.get("date")?.value);
    const time = moment(
      this.reservation?.time ||
        this.form.get("reservation")?.get("time")?.value,
      "HH:ss"
    ).format("HH:ss:mm");
    if (!this.availabilitiesBlock) return undefined;
    return this.availabilitiesBlock.find(
      (ab) => ab.date === date && ab.time === time && !!ab.languageIso
    );
  }

  get reservationNotWaitingOrDraft() {
    return this.reservation && this.reservation.state !== "waiting" && this.reservation.state !== "draft";
  }

  get currentUrl() {
    return this.router.url.includes("/dashboard");
  }

  get isManualReservation() {
    return !this.reservation || this.reservation.type === "manual";
  }

  get lang() {
    return locale().locale;
  }

  get priceCents() {
    return (
      this.reservation?.experiencePriceLabels?.find((e) => e.islabel1)
        ?.price_cents ||
      this.experience?.prices.find(
        (x) => x.position == 0 && x.priceType == "LABEL"
      )?.priceCents ||
      0
    );
  }

  get experienceType() {
    return (
      this.experience?.metaDatas?.find((x) =>
        experienceTypeCodes.includes(x.metadataValue)
      )?.metadataValue || "tasting"
    );
  }

  get experienceLanguages() {
    return (
      this.experience?.metaDatas
        .filter((x) => x.metadataValue.startsWith("lang_"))
        ?.map((i) => i.metadataValue.split("_")[1]) || []
    );
  }

  get coupon() {
    return this.form.get("reservation")?.get("coupon_code")?.value;
  }

  get stripeConnected() {
    return this.companyInt?.accept_stripe_payments;
  }

  get invoiceData() {
    return !!this.reservation?.otherData?.invoiceData && JSON.parse(this.reservation?.otherData?.invoiceData);
  }

  get invoiceKeys() {
    let k = Object.keys(this.invoiceData || {});
    // impose manual order
    return [
      "companyName",
      "vat",
      "fiscalCode",
      "sdi",
      "billingCountry",
      "billingAddress",
      "billingCity",
      "billingProvince",
      "billingZipCode",
    ].filter((x) => k.includes(x));
  }

  getExperienceName(experience?: ExperienceDWS) {
    if (!experience) return "";
    return (
      experience.details.titles.find(
        (x) => x.languageIsoCode.toLowerCase() == this.lang
      )?.shortLabel || ""
    );
  }

  getLabelOrExtra(
    position: number,
    type: "LABEL" | "EXTRA" = "LABEL",
    language: string = this.lang
  ) {
    return (
      this.experience?.prices
        .find((x) => x.position == position && x.priceType == type)
        ?.titles.filter(
          (x: any) => x.languageIsoCode.toLowerCase() == language
        )[0]?.text || ""
    );
  }

  getRoom(roomId: string) {
    return this.rooms.find((room) => room.id == roomId);
  }

  getEmployee(employeeId: string) {
    return this.team.find((employee) => employee.id == employeeId);
  }

  changePaid(event: MatCheckboxChange) {
    if (event.checked) {
      if (this.form.get("reservation")?.get("due")?.value) {
        this.temporaryFormValues["due"] = this.form
          .get("reservation")
          ?.get("due")?.value;
        this.temporaryFormValues["paidAmout"] = this.form
          .get("reservation")
          ?.get("paidAmout")?.value;
      }

      var paid = this.form.get("reservation")?.get("paidAmout")?.value;
      var due = this.form.get("reservation")?.get("due")?.value;
      var total = this.form.get("reservation")?.get("total")?.value as number;
      this.form.patchValue({
        reservation: {
          paidAmout: total,
          due: 0,
        },
      });
    } else {
      if (this.temporaryFormValues["due"]) {
        this.form
          .get("reservation")
          ?.get("due")
          ?.setValue(this.temporaryFormValues["due"]);
        this.form
          .get("reservation")
          ?.get("paidAmout")
          ?.setValue(this.temporaryFormValues["paidAmout"]);
      }
    }
  }

  constructor(
    private fb: FormBuilder,
    private reservationsService: ReservationsService,
    private accountsService: AccountsService,
    private giftsService: GiftsService,
    private contactsService: ContactsService,
    private dlg: MatDialog,
    private dialogForClone: MatDialog,
    private experienceAvailabilitiesService: ExperienceAvailabilitiesService,
    private availabilityServiceDws: ExperienceAvailabilityService,
    private manualReservationListenerService: ManualReservationRequestsListenerService,
    private reservationFormOptionsService: HostReservationFormOptionsService,
    private reservationDetailService: ReservationDetailService,
    private backgroundService: BackgroundService,
    private availabilityBlockService: AvailabilityBlockService,
    private router: Router,
    private experienceAvailabilityService: ExperienceAvailabilityService,
    private paymentMethodsService: PaymentMethodsService,
    private translate: TranslateService,
    private statisticsService: StatisticsService,
    private contactsTagsService: ContactsTagsService,
    private externalCalendarSyncService: ExternalCalendarSyncService,
    private languageService: LanguagesService,
    private experienceEventsAvailabilitiesService: ExperienceEventsAvailabilitiesService,
    private reservationContactsService: ReservationContactsService,
    private wsPaymentsService: WSPaymentsService,
    private cashRegisterService: CashRegisterService,
    private hostCouponsService: HostCouponsService
  ) {
    this.clientForm = this.fb.group({
      firstname: ["", Validators.required],
      lastname: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      phone: [""],
    });
    this.language = translate.getDefaultLang();
  }

  ngOnInit(): void {
    this.form = this.generateForm();

    this.accountsService.company$
      .pipe(filter((x) => x != null))
      .subscribe((x) => {
        this.companyInt = x!;
        this.companyId$.next(x!.id);
        this.companyId = x!.id;

        if (this.reservationId) {
          // First, get the reservation details
          observe(
            this.reservationDetailService.getV2(this.reservationId).pipe(
              switchMap((r) =>
                forkJoin([
                  // Use the obtained experienceId in the next service call
                  this.languageService.list(),
                  this.reservationFormOptionsService.get(
                    this.companyId,
                    r.experienceId!
                  ),
                  this.wsPaymentsService.getPaymentLink({
                    reservationId: r.id,
                  }).pipe(catchError(() => of({content: []}))),
                ]).pipe(
                  map(([languages, opts, paymentLinkPage]) => ({
                    reservation: r,
                    languages: languages.content, // Example transformation for languages
                    opts: opts, // Example transformation for options, modify as needed
                    paymentLink: paymentLinkPage.content?.length
                      ? paymentLinkPage.content[0]
                      : null,
                  }))
                )
              )
            )
          ).subscribe(({ reservation, languages, opts, paymentLink }) => {
            // SET LANGS
            this.languages = languages;
            reservation.language = this.languages.find(
              (e) => e.iso == reservation.languageIso
            );

            // SET RESERVATION IF ANY
            this.reservation = reservation;

            const isTourOperator = reservation.otherData?.isTourOperator;
            if (isTourOperator) {
              this.isTourOperator = isTourOperator;
            }

            this.manageOpt(opts);

            this.paymentLinkData = paymentLink;

            // TODO GET activatedExperienceAutomations and use them, now is always CLOSE
            this.isDraftable = this.reservation?.state == "draft" || false;
            if (!this.isDraftable) {
              const isCloseMatchingSlotLanguagesInTheSameExperienceActive =
                opts.activatedExperienceAutomations.indexOf(
                  "CLOSE_MATCHING_SLOT_LANGUAGES_IN_THE_SAME_EXPERIENCE"
                ) > -1;
              const isCloseMatchingSlotInOtherExperiencesActive =
                opts.activatedExperienceAutomations.indexOf(
                  "CLOSE_MATCHING_SLOTS_IN_OTHER_EXPERIENCES"
                ) > -1;
              const slotClosingPopupShouldNotAppear =
                isCloseMatchingSlotLanguagesInTheSameExperienceActive &&
                isCloseMatchingSlotInOtherExperiencesActive;
              this.shouldAskForSlotClosing = !slotClosingPopupShouldNotAppear;
            } else {
              this.shouldAskForSlotClosing = false;
            }

            this.setFormValues();

            this.onInitMc();

            this.checkIfPaymentFailed();

            this.getResCashRegisterMetadata();
          });
        } else {
          this.isDraftable = true;

          observe(
            forkJoin([
              this.languageService.list(),
              this.reservationFormOptionsService.get(this.companyId, ""),
            ])
          ).subscribe(([languages, opts]) => {
            this.languages = languages.content;

            this.shouldAskForSlotClosing = false;

            this.manageOpt(opts);
            this.setFormValues();
            this.onInitMc();
          });
        }
      });
  }

  manageOpt(opts: DWSFormOptions) {
    this.availableVisitReasons = opts.visitReasons;
    this.availableExperiences = opts.experiences;

    this.experience = this.getExperienceOrDefault();
    this.manualOrigins = this.isTourOperator
      ? opts.manualOrigins
      : opts.manualOrigins.filter((x) => x.id !== 5);
    this.paymentMethods = opts.paymentMethods;
    this.availabilitiesBlock = opts.availabilityBlocks;
    this.hasEnabledAutomations = opts.activatedExperienceAutomations.length > 0;
    const rooms = opts.rooms;
    this.rooms$.next(rooms);

    const employees = opts.employees;

    this.employees$.next(employees);
    this.countries = opts.countries;

    this.availableExperiencesActive = opts.experiences
      .filter((r) => r.details.active === true)
      .sort((a, b) => {
        if (a.details.priority === null && b.details.priority === null) {
          return a.details.titles.find(
            (x) => x.languageIsoCode.toLowerCase() == this.lang
          ).text >
            b.details.titles.find(
              (x) => x.languageIsoCode.toLowerCase() == this.lang
            ).text
            ? 1
            : -1;
        } else if (a.details.priority === null) {
          return 1;
        } else if (b.details.priority === null) {
          return -1;
        } else {
          return a.details.priority! > b.details.priority! ? 1 : -1;
        }
      });

    this.filteredExperiences = this.availableExperiencesActive;
  }

  onInitMc() {
    this.listenerForFetchAvailability();

    this.rooms$.subscribe((rooms) => {
      this.rooms = rooms;
      this.patchFormRoom();
    });

    this.employees$.subscribe((employees) => {
      this.team = employees;
      this.patchFormEmployee();
    });

    this.hostCouponsService.listValidCoupons().subscribe((coupons) => {
      this.availableCoupons = coupons;
    });


    let pipeSearchControl = (control: FormControl, isMasterContactControl: boolean) => {
      return control.valueChanges.pipe(
        startWith(""),
        filter((v: string | any | null) => {
          return typeof v === "string" && !!v && v.trim() !== "";
        }),
        tap(() => (this.loadingClients = true)),
        debounceTime(300),
        tap(() => {
          this.clients = [];
          this.loadingClients = true;
        }),
        switchMap((v) =>
          this.contactsService.list({
            q: v,
            wineryIds: [this.companyId],
            includeUnsubscribed: false,
            includeSubscribed: false,
            tourOperators: this.isTourOperator && isMasterContactControl,
          })
        )
      );
    };

    this.masterClients$ = pipeSearchControl(this.masterSearchControl, true);
    this.clients$ = pipeSearchControl(this.searchControl, false);

    let f = (c: Page<Contact>) => {
      this.clients = c.content.filter((c) => c.email != null);
      this.loadingClients = false;
    };
    this.masterClients$.subscribe(f);
    this.clients$.subscribe(f);

    const r = this.form.get("reservation")!;
    if (this.canEdit) {
      if (!this.reservationToClone) {
        r.get("date")!.valueChanges.subscribe((v: any) => {
          if (v) {
            if (typeof v === "string") v = parseISO(v);
            const d = v instanceof Date ? v : v.toDate();
            this.availableTimes = ExperienceSlots.availableTimesFor(
              this.availableSlots,
              d
            );
            if (this.reservation?.type === "automatic") {
              if (this.availableTimes.length == 0)
                this.availableTimes.push(
                  this.reservation!.time!.split(":", 2).join(":")
                );
            }
            // SORT availableTimes
            this.availableTimes = this.availableTimes.sort((a, b) => {
              return a.localeCompare(b);
            });
            r.get("time")!.enable();
          }
        });
      }
    }
    if (this.reservationToClone) {
      //language & experience disabled with html
      r.get("time")!.disable();
      r.get("date")!.disable();
      if (!!!!r.get("event-date")!) {
        r.get("event-date")!.disable();
      }
    } else {
      r.patchValue({
        paymentMethod: "winery",
      });
      r.get("lang")!.disable();

      r.get("time")!.valueChanges.subscribe((time) => {
        if (!!time) {
          const date = moment(r.get("date")!.value as Moment);
          const hours = time.split(":")[0];
          const minutes = time.split(":")[1];
          date.add(minutes, "minutes").add(hours, "hours");
          if (date.isBefore(moment().add(-24, "hours")))
            r.get("notify_contact")?.setValue(false);
        }

        // this.availableLanguages = this.experience.
        r.get("lang")!.enable();
      });
    }

    if (
      this.reservation?.state === "confirmed" &&
      !(this.reservation.type === "manual")
    ) {
      this.isConfirmedReservation = true;
    }

    if (
      this.reservation?.state == "revoked" ||
      this.reservation?.state == "canceled" ||
      this.reservation?.state == "rejected" ||
      this.reservation?.state == "deleted"
    ) {
      observe(
        this.reservationDetailService.getReservationHistory(
          this.reservation.id!,
          "edit_gift"
        )
      ).subscribe((history) => {
        if (history.length > 0) {
          this.giftAssociatedBeforeRevocation = history[0].externalEntityPk;
        }
      });
    }

    if (this.reservation) {
      this.getUpdatedAvailabilities(
        this.reservation.date!,
        this.reservation.time!,
        this.reservation.experienceId!
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes["reservation"]) return;
    const reservationChanges = changes["reservation"];
    if (reservationChanges.currentValue && !reservationChanges.isFirstChange())
      this.setFormValues();
  }

  checkIfPaymentFailed(): void {
    if ( this.reservation?.state == 'waiting' && (
      this.reservation?.otherData?.paymentFailed === true || this.reservation?.otherData?.paymentFailed === 'true'
    )) {
      this.dlg.open(NoticeComponent, {
        data: {
          isWarning: false,
          text: tr("Attention!"),
          items: [tr("This reservation hasn't been confirmed because the payment failed. Please check the payment status and try again.")],
        },
        minWidth: 550,
        maxWidth: 550,
      });
    }
  }

  getUpdatedAvailabilities(date: string, time: string, experienceId: string) {
    const wineryId = this.companyId;
    if (date && time && experienceId) {
      var dayStart = moment(date)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      var dayEnd = moment(date)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
        .add(1, "day")
        .toDate();
      this.availabilityServiceDws
        .getAvailabilities(wineryId, dayStart, dayEnd)
        .subscribe((data) => {
          var experienceAva = data.filter(
            (x) => x.experience.id === experienceId
          );
          this.availabilities2 = experienceAva;
        });
    }
  }

  canEditPartecipants(): boolean {
    return (
      this.canEditField("partecipants") &&
      ((this.reservation && !this.confirmed && !this.waiting) ||
        (this.canEdit && this.experience !== null) ||
        false)
    );
  }

  getCountForExperience(): ExperienceAvailability2 | undefined {
    var time =
      this.reservation?.time ||
      this.form.get("reservation")?.get("time")?.value;
    var date =
      this.reservation?.date ||
      this.form.get("reservation")?.get("date")?.value;
    var founded = this.availabilities2.find(
      (x) => x.day === date && x.time === time
    );
    if (founded) {
      return founded;
    } else {
      return undefined;
    }
  }

  fillPriceLabels() {
    const e = this.experience;
    const control = this.experiencePriceLabelsFormArray;
    control.clear();
    if (!e) return;
    for (const pl of e.prices
      .filter((p) => p.priceType == "LABEL")
      .sort(({ position: a }, { position: b }) => a - b)) {
      control.push(ExperiencePriceLabel.createFormGroup(this.fb, pl));
    }
  }

  fillPriceExtras() {
    const e = this.experience;
    const control = this.experiencePriceExtrasFormArray;
    control.clear();
    if (!e) return;
    for (const pe of e.prices
      .filter((p) => p.priceType == "EXTRA")
      .sort(({ position: a }, { position: b }) => a - b)) {
      control.push(ExperiencePriceExtra.createFormGroup(this.fb, pe));
    }
  }

  private patchFormPriceLabels(r: ReservationDTO) {
    let priceLabels = [...r.experiencePriceLabels!];
    if (
      !priceLabels.find((x) => x.islabel1 == true) &&
      priceLabels.find((x) => x.position == 0)
    ) {
      priceLabels.forEach((x) => (x.position += 1));
    }
    priceLabels = priceLabels.filter((x) => !x.islabel1);
    const controls = this.formArrayPriceLabels;
    for (const c of controls) {
      if (c.get("position")?.value == 0) {
        c.patchValue({
          quantity: r.guestCount01 || 0,
          price_cents: this.priceCents || 0,
          islabel1: true,
        });
      } else {
        const pl: any =
          priceLabels.find((x) => x.position == c.get("position")?.value) || {};
        c.patchValue({
          quantity: pl.quantity || 0,
        });
        if (pl.price_cents !== null && pl.price_cents !== undefined) {
          c.patchValue({
            price_cents: pl.price_cents,
          });
        }
      }
    }
  }

  private patchFormPriceExtras(r: ReservationDTO) {
    let priceExtras = [...r.experiencePriceExtras!];
    const controls = this.experiencePriceExtrasFormArray.controls;
    for (const c of controls) {
      const pe: any =
        priceExtras.find((x) => x.position == c.get("position")?.value) || {};
      c.patchValue({
        quantity: pe.quantity || 0,
      });
      if (pe.price_cents !== null && pe.price_cents !== undefined) {
        c.patchValue({
          price_cents: pe.price_cents,
        });
      }
    }
  }

  getGuestCount(n: number) {
    return (
      this.reservation?.experiencePriceLabels?.find((e) => e.position === n)
        ?.quantity || 0
    );
  }

  dateAvailableFilter = (d: Moment) => {
    if (!this.experience) return false;
    if (
      this.reservation &&
      isSameDay(
        d.toDate(),
        parse(this.reservation!.date!, "yyyy-MM-dd", new Date())
      )
    ) {
      return true;
    }
    return ExperienceAvailability.isDayAvailable(
      this.availabilities,
      d.toDate()
    );
  };

  openGiftDialog() {
    if (this.reservation?.giftId || this.giftAssociatedBeforeRevocation) {
      observe(
        this.gift
          ? of(this.gift)
          : this.giftsService.getGiftById(
              (this.reservation?.giftId || this.giftAssociatedBeforeRevocation)!
            )
      ).subscribe(
        (gift) => {
          this.gift = gift;
          const dlg = this.dlg.open(GiftDetailComponent, {
            width: "50%",
            minWidth: 1000,
            maxWidth: 1000,
            data: {
              gift,
              returnToReservation: true,
            },
          });
          dlg.afterClosed().subscribe((res) => {});
        },
        (error) => {
          if (error.status === 404) {
            toast(tr("Cannot find this gift."));
            console.log(error);
          } else toast(tr(this.messageError));
        }
      );
    }
  }

  compareStatus = (a: MappedStatus, b: MappedStatus) => {
    return a.type === b.type;
  };

  get orderCountries(): HostReservationFormOptions.Country[] {
    return this.countries.sort((a, b) => {
      return this.language === "en"
        ? a.nameEn!.localeCompare(b.nameEn || "")
        : a.nameIt!.localeCompare(b.nameIt || "");
    });
  }

  getOrderCountryByIso(
    iso: string
  ): HostReservationFormOptions.Country | undefined {
    return this.orderCountries.find((c) => c.iso === iso);
  }

  private extractDateString = (date: any) => {
    if (moment.isMoment(date)) return (date as Moment).format("YYYY-MM-DD");
    else if (date instanceof Date) return format(date, "yyyy-MM-dd");
    else if (moment(date, "YYYY-MM-DD").isValid())
      return moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
    return date as string;
  };

  private getExperienceOrDefault(): ExperienceDWS | undefined {
    return this.availableExperiences.find(
      (e) =>
        this.reservation?.experienceId == e?.id ||
        this.reservation?.experience?.experienceId == e?.id ||
        this.reservationToClone?.experienceId == e?.id ||
        this.reservationToClone?.experience?.experienceId == e?.id
    );
  }

  patchFormRoom() {
    const reservationRoom = this.form.get("reservation")!.get("room");
    if (this.reservation?.roomId) {
      reservationRoom!.enable();
      reservationRoom!.setValue(this.getRoom(this.reservation.roomId));
    }
    if (this.reservationToClone?.roomId) {
      reservationRoom!.enable();
      reservationRoom!.setValue(this.getRoom(this.reservationToClone.roomId));
    }
  }

  patchFormEmployee() {
    const reservationEmployee = this.form.get("reservation")!.get("employee");
    if (this.reservation?.employeeId) {
      reservationEmployee!.enable();
      reservationEmployee!.setValue(
        this.getEmployee(this.reservation.employeeId)
      );
    }
    if (this.reservationToClone?.employeeId) {
      reservationEmployee!.enable();
      reservationEmployee!.setValue(
        this.getEmployee(this.reservationToClone.employeeId)
      );
    }
  }

  setDateAndTimeForm() {
    const eventDate = this.form.get("reservation")!.get("event_date")?.value;
    if (eventDate) {
      const date = moment(eventDate).format("YYYY-MM-DD");
      const time = moment(eventDate).format("HH:mm");
      if (this.experienceType == "event") {
        this.form.patchValue({
          reservation: {
            date: date,
            time: time,
          },
        });
      }
    }
  }

  setReservationStatusClicked(status: "waiting" | "confirmed" | "rejected") {
    this.editable.state = status;
    this.form.patchValue({
      reservation: {
        status: ReservationDetail.status(undefined, status),
      },
    });
  }

  statusObjectFor(
    status:
      | "draft"
      | "waiting"
      | "confirmed"
      | "rejected"
      | "completed"
      | "canceled"
      | "revoked"
  ) {
    return ReservationDetail.status(undefined, status);
  }

  async cancelClicked() {
    this.form.get("reservation")?.get("discount")?.markAsPristine(); // couldn't find out where is it touched
    if (this.form.pristine && this.clientForm.pristine)
      return this.closeClick.emit();
    const r = await confirm.yesno(
      tr(
        "By leaving now you will lose all the changes made to the reservation."
      ),
      tr("Leave"),
      tr("Back")
    );
    if (r) this.closeClick.emit();
  }

  async cloneReservationClicked() {
    if (this.form.pristine && this.clientForm.pristine) {
      this.closeClick.emit();
    } else {
      const r = await confirm.yesno(
        tr(
          "By leaving now you will lose all the changes made to the reservation."
        ),
        tr("Leave"),
        tr("Back")
      );
      if (r) this.closeClick.emit();
    }
    this.dialogForClone.open(ReservationComponent, {
      data: {
        reservationToClone: this.reservation,
        experience: this.experience,
        isTourOperator: this.isTourOperator,
      },
      minWidth: 1031,
    });
  }

  get externalCalendarSync$() {
    return this.externalCalendarSyncService.sync(this.reservation?.id!);
  }

  getSlotsPrincipalMessage(mode: 'slots' | 'languages' | 'multiple', actions: string[] = []) {
    if (mode === 'slots') {
      if (this.isAvailabilityBlock) {
        return tr(
          "Do you want to open the availability of the other experiences at the same time?"
        );
      } else {
        return tr(
          "Do you want to close the availability of the other experiences at the same time?"
        );
      }
    } else if (mode === 'languages') {
      if (this.isLanguageBlock) {
        return tr("Do you want to enable other languages for this slot?");
      } else {
        return tr("Do you want to disable other languages for this slot?");
      }
    } else {
      return tr("Do you want to execute the following actions? ") + actions.map(a => tr(a)).join(", ");
    }
  }

  getConfirmedButtonMessageOpenSlots(mode: "slots" | "languages") {
    if (mode === "slots") {
      if (!this.isAvailabilityBlock) {
        return tr("Close Slot");
      } else {
        return tr("Open Slot");
      }
    } else {
      if (!this.isLanguageBlock) {
        return tr("Disable Languages");
      } else {
        return tr("Enable Languages");
      }
    }
  }

  getConfirmedButtonMessageCloseSlots(mode: "slots" | "languages") {
    if (mode === "slots") {
      if (this.isAvailabilityBlock) {
        return tr("Keep Closed");
      } else {
        return tr("Keep Open");
      }
    } else {
      if (this.isLanguageBlock) {
        return tr("Keep Disabled");
      } else {
        return tr("Keep Enabled");
      }
    }
  }

  async clickedOpenSlots(skipConfirmation = false) {
    const shouldChangeStateSlots = skipConfirmation || await confirm.yesno(
      this.getSlotsPrincipalMessage("slots"),
      this.getConfirmedButtonMessageOpenSlots("slots"),
      this.getConfirmedButtonMessageCloseSlots("slots")
    );
    if (!shouldChangeStateSlots) return of(null);

    if (this.isAvailabilityBlock) {
      return await this.openAvailabilities('slots', skipConfirmation);
    }
    return of(null);
  }

  async clickedCloseSlots(skipConfirmation = false) {
    const shouldChangeStateSlots = skipConfirmation || await confirm.yesno(
      this.getSlotsPrincipalMessage("slots"),
      this.getConfirmedButtonMessageOpenSlots("slots"),
      this.getConfirmedButtonMessageCloseSlots("slots")
    );
    if (!shouldChangeStateSlots) return of(null);

    this.setDateAndTimeForm();
    if (!this.isAvailabilityBlock) {
      return await this.closeAvailabilities('slots', skipConfirmation);
    }
    return of(null);
  }

  async openAvailabilities(mode: 'slots' | 'languages', isMultipleSelection: boolean): Promise<Observable<any>> {
    if ((mode == 'slots' && !this.isAvailabilityBlock) || (mode == 'languages' && !this.isLanguageBlock)) return of(null);
    const obs = this.availabilityBlockService.remove(mode === 'slots'
      ? this.isAvailabilityBlock?.id!
      : this.isLanguageBlock?.id!
    );
    observe(obs).subscribe(
      () => {
        if (mode === "slots") {
          const date = new Date(this.reservation!.date!);
          this.experienceAvailabilityService
            .getAvailabilities(this.reservation!.wineryId!, date, date)
            .pipe(
              map((availabilities) =>
                availabilities.filter(
                  (a) =>
                    a.time === this.reservation?.time &&
                    a.active &&
                    a.experience.id !== this.reservation?.experienceId &&
                    !a.isSingleSlot
                )
              )
            )
            .subscribe(
              (availabilities) => {
                this.dlg
                  .open(NoticeComponent, {
                    minWidth: 550,
                    maxWidth: 550,
                    panelClass: "no-overflow",
                    data: !!availabilities?.length
                      ? {
                          isWarning: false,
                          text: tr("The following slots have been opened:"),
                          items: availabilities.map(
                            (uv) =>
                              `${uv.experience.title} ${moment(
                                uv.time,
                                "HH:mm:ss"
                              ).format("HH:mm")}`
                          ),
                        }
                      : {
                          isWarning: false,
                          text: tr("There are no slots to open"),
                          items: [],
                        },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    this.closeClick.emit();
                    this.reservationsService.updated$.next();
                  });
              },
              () => toast(tr("All slots have been opened with success"))
            );
        } else {
          this.dlg
            .open(NoticeComponent, {
              minWidth: 550,
              maxWidth: 550,
              panelClass: "no-overflow",
              data: {
                isWarning: false,
                text: tr("Active languages for this slot:"),
                items: this.experienceLanguages.map((l) => tr("lang_" + l)),
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.closeClick.emit();
              this.reservationsService.updated$.next();
            });
        }
      },
      () => toast(tr(this.messageError))
    );

    return obs;
  }



  async closeAvailabilities(mode: 'slots' | 'languages', isMultipleSelection: boolean): Promise<Observable<any>> {
    const wineryId = this.reservation?.wineryId;
    const date = this.reservation?.date;
    const time = this.reservation?.time;
    const experienceId = this.reservation?.experienceId;
    const languageIso = this.reservation?.languageIso;

    const availabilityBlock = {
      wineryId,
      date,
      time,
      experienceId,
      languageIso,
      disableSlots: mode === "slots",
      disableLanguages: mode === "languages",
    } as AvailabilityBlock;

    const obs = this.availabilityBlockService.create(availabilityBlock).pipe(share());

    observe(obs).subscribe(
      (unclosedAvailabilities) => {
        if (mode === "slots") {
          if (unclosedAvailabilities.length > 0) {
            const dialog = this.dlg.open(NoticeComponent, {
              minWidth: 550,
              maxWidth: 550,
              panelClass: "no-overflow",
              data: {
                isWarning: true,
                text: tr("All slots have been closed except:"),
                items: unclosedAvailabilities.map(
                  (uv) =>
                    `${TranslateDomainPipe.transform(
                      uv,
                      "experienceName",
                      "pascal"
                    )} at ${moment(uv.experienceTime, "HH:mm:ss").format(
                      "HH:mm"
                    )}`
                ),
              },
            });
            dialog.afterClosed().subscribe(() => {
              this.closeClick.emit();
              this.reservationsService.updated$.next();
            });
          } else {
            this.dlg
              .open(NoticeComponent, {
                minWidth: 550,
                maxWidth: 550,
                panelClass: "no-overflow",
                data: {
                  isWarning: false,
                  text: tr("All slots have been closed with success"),
                  items: [],
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.closeClick.emit();
                this.reservationsService.updated$.next();
              });
          }
        } else {
          this.dlg
            .open(NoticeComponent, {
              minWidth: 550,
              maxWidth: 550,
              panelClass: "no-overflow",
              data:
                this.experienceLanguages?.length > 1
                  ? {
                      isWarning: false,
                      text: tr("Languages deactivated for this slot:"),
                      items: this.experienceLanguages
                        .filter((l) => l !== languageIso)
                        .map((l) => tr("lang_" + l)),
                    }
                  : {
                      isWarning: false,
                      text: tr("There are no other languages to deactivate"),
                      items: [],
                    },
            })
            .afterClosed()
            .subscribe(() => {
              this.closeClick.emit();
              this.reservationsService.updated$.next();
            });
        }
      },
      (e) => {
        if (e && e.error && e.error.status === 400) {
          const confirmed = confirm.yes(tr(e.error.message));
          if (!confirmed) return;
        } else {
          toast(tr(this.messageError));
        }
      }
    );

    return obs;
  }

  getPrincipalMessage(currentState: string | undefined, selectedState: string) {
    if (this.isManualReservation) {
      if (this.hasAnyAvailabilityForDateDay) {
        return this.getBlockMessage();
      }
    }
    if (
      currentState === "waiting" &&
      selectedState === "confirmed" &&
      this.shouldAskForSlotClosing
    ) {
      return this.getBlockMessage();
    }
    return tr("Are you sure you want to save the changes?");
  }

  private getBlockMessage() {
    if (!this.isAvailabilityBlock) {
      return tr(
        "Do you want to close the availability of the other experiences at the same time?"
      );
    } else {
      return tr(
        "Do you want to open the availability of the other experiences at the same time?"
      );
    }
  }

  getConfirmedButtonMessage(
    currentState: string | undefined,
    selectedState: string
  ) {
    if (this.isManualReservation) {
      if (this.hasAnyAvailabilityForDateDay) {
        return this.getConfirmedBlockButton();
      }
    } else if (currentState === "waiting" && selectedState === "confirmed") {
      return this.getConfirmedBlockButton();
    }
    return tr("Save");
  }

  private getConfirmedBlockButton() {
    if (!this.isAvailabilityBlock) {
      return tr("Close Slot");
    } else {
      return tr("Open Slot");
    }
  }

  private getIgnoreBlockButton() {
    if (!this.isAvailabilityBlock) {
      return tr("Keep Open");
    } else {
      return tr("Keep Closed");
    }
  }

  /****************************** */

  /** SAVE RESERVSATION NEW LOGIC */

  async saveDraftClicked() {
    this.isDraft = true;
    await this.doSave();
  }

  async saveClicked() {
    this.isDraft = false;
    await this.doSave();
  }

  async doSave() {
    const reservation = this.reservation;
    const selectedState = this.form.value.reservation.status.type;
    const currentState = reservation?.state;

    var isValidated = this.validateReservationData();
    if (!isValidated) return;

    // this logic is verifying change state 'waiting' for 'confirmed' or in creation manual reservation (is true) and has any availability for date/hour
    const canUpdateAvailabilities =
      (currentState === "waiting" && selectedState === "confirmed") ||
      (this.isManualReservation && this.hasAnyAvailabilityForDateDay);

    let openSlots = false,
      closeSlots = false;

    // if (this.shouldAskForSlotClosing) {
    //   const shouldContinue = await confirm.yesno(
    //     this.getPrincipalMessage(currentState, selectedState),
    //     this.getConfirmedButtonMessage(currentState, selectedState),
    //     this.getBackButtonMessage(currentState, selectedState)
    //   );

    //   // this logic is for considerate shouldContinue when buttons lock/open is not visible
    //   if (!canUpdateAvailabilities && !shouldContinue) return;

    //   // this logic is for considerate shouldContinue when buttons lock/open is visible and allow open or close all slots
    //   if (canUpdateAvailabilities && shouldContinue) {
    //     if (!this.isAvailabilityBlock) {
    //       closeSlots = true;
    //     } else {
    //       openSlots = true;
    //     }
    //   }
    // } else {
    // this logic is for BYPASSING THE ENORMOUS INSANITY ABOVE.
    const shouldContinue = await confirm.yesno(
      tr("Are you sure you want to save the changes?"),
      tr("Save"),
      tr("Back")
    );

    if (!shouldContinue) return;
    // }

    this.saveReservation(openSlots, closeSlots);
  }

  validateReservationData(): boolean {
    const reservation_experience = this.experience;
    if (!reservation_experience) {
      toast(
        "Before saving the reservation, make sure to select the experience"
      ).then();
      return false;
    }

    const reservation_date =
      this.form.get("reservation")!.get("date")!.value || undefined;
    const reservation_time =
      this.form.get("reservation")!.get("time")!.value || undefined;
    const reservation_event_date =
      this.form.get("reservation")!.get("event_date")!.value || undefined;
    if (
      !(
        (!!!!reservation_date && !!!!reservation_time) ||
        !!!!reservation_event_date
      )
    ) {
      toast(
        "Before saving the reservation, make sure to compile and verify the reservation date"
      ).then();
      return false;
    }

    const langErrors = this.form.controls.reservation.get("lang")?.errors;
    if (langErrors && langErrors.required) {
      toast(tr("Please fill in the booking language"));
      return false;
    }

    const labels = this.form
      .get("reservation")
      ?.get("experience_price_labels")?.value;
    const labelsQuantity =
      labels != null
        ? labels.reduce(
            (quantityAcc: number, label: ExperiencePriceLabel) =>
              quantityAcc + label.quantity,
            0
          ) || 0
        : 0;
    if (labelsQuantity == 0) {
      toast(
        "No participant selected. Add attendees to register your booking."
      ).then();
      return false;
    }

    if (this.form.get("reservation")?.get("total")?.value < 0) {
      toast("Total can't be negative").then();
      return false;
    }

    if (!this.isDraft) {
      let validateContact = function (contact: any): boolean {
        return (
          !contact ||
          (!contact.firstName &&
            !contact.lastName &&
            !contact.email &&
            !contact.phoneNumber &&
            !contact.countryIso)
        );
      };
      if (validateContact(this.masterContactControl?.value)) {
        toast("Please add the main contact").then();
        return false;
      }
      if (
        this.secondaryContactsControls?.reduce(
          (acc, control) => acc || validateContact(control.value),
          false
        )
      ) {
        toast(
          "Please delete the empty participants or fill in the respective data"
        ).then();
        return false;
      }
    }

    return true;
  }

  async saveReservation(
    openSlots: boolean = false,
    closeSlots: boolean = false
  ) {
    this.isSaving(true);
    try {
      let r = this.patchReservationFromForm();
      this.reservationDetailService.saveV2(r, openSlots, closeSlots).subscribe(
        (re) => {
          if (re.hasError) {
            toast(
              re.messages
                .filter((m: any) => m.type === "ERROR")
                .map((m: any) => m.message)
                .join("\n")
            );
            this.isSaving(false);
          } else {
            if (re.hasWarning) {
              toast(
                re.messages
                  .filter((m: any) => m.type === "WARN")
                  .map((m: any) => m.message)
                  .join("\n")
              );
            }
            this.reservationsService.updated$.next();
            this.waitForSaveReservationNew(re.reservation);
          }
        },
        (error) => {
          console.error(error + " - {}", this.messageError);
          toast(tr(this.messageError));
          this.isSaving(false);
        }
      );
    } catch (error) {
      console.error(error);
      toast(tr(this.messageError));
      this.isSaving(false);
    }
  }

  waitForSaveReservationNew(reservation: ReservationDTO) {
    if (reservation.id)
      this.manualReservationListenerService.addRequestIdToListener(
        reservation.id,
        reservation.id,
        reservation.state!
      );
    this.closeClick.emit(reservation);
  }

  /************************************** */

  addNewClientClicked(id: number | string) {
    this.clientSaveCallback = (client: Client) => this.clientAdded(client);
    this.reservationContactToEditId = id;
    this.clientSideNav.open();
    setTimeout(() => this.trigger.closePanel());
  }

  removeClientClicked(id: number | string) {
    this.clients = [];
    this.form.controls.clientSearch.reset();
    this.searchControl.reset();
    const index = this.form
      .get("reservation")
      ?.get("reservationContacts")
      ?.value.findIndex((control: any) => control.id === id);
    (typeof id === "number"
      ? this.reservationContactsService.delete(id)
      : of(undefined)
    ).subscribe(
      () => {
        (
          this.form.get("reservation")?.get("reservationContacts") as FormArray
        ).removeAt(index);
        this.setContactsArraysWithValues();
        toast(tr("Resevation contact removed successfully"));
      },
      (error) => {
        toast(tr("Error deleting contact. Please try again."));
      }
    );
  }

  filterClients(value: any): any {
    const filterValue = this.normalizeValue(value);

    return this.clients.filter((client: any) => {
      const clientFullName = client.firstName + " " + client.lastName;
      return this.normalizeValue(clientFullName).includes(filterValue);
    });
  }

  normalizeValue(value: string): any {
    if (typeof value === "string") {
      return value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim();
    }
  }

  associateContact(contact: DWS.Contact, id: number | string) {
    let idInternal = id;
    let control = (
      this.form.get("reservation")!.get("reservationContacts")! as FormArray
    ).controls.find((control: any) => control.get("id")?.value === idInternal);
    if (!control && this.masterContactControl) return;
    if (!control && !this.masterContactControl) {
      const newId = (crypto as any).randomUUID();
      let fg = this.fb.group({
        id: [newId],
        name: [""],
        firstName: [""],
        lastName: [""],
        email: [""],
        phoneNumber: [""],
        countryIso: [""],
        contactType: ["MASTER"],
        crmContactId: [""],
        manualMarketing: [false],
      });
      (
        this.form.get("reservation")!.get("reservationContacts") as FormArray
      ).push(fg);
      control = this.masterContactControl;
      idInternal = newId;
    }
    control!.patchValue({
      name: contact.name,
      firstName:
        !contact.firstName && !contact.lastName
          ? contact.companyName
          : contact.firstName,
      lastName: contact.lastName || "",
      email: contact.email,
      phoneNumber: contact.phone,
      countryIso: contact.countryOfResidenceIso,
      crmContactId: contact.id,
    });

    this.contactsData[idInternal] = {} as any;
    this.contactDetailOpen[idInternal] = false;
    this.contactsNotMatchingAlerts[idInternal] = {};

    this.retrieveCRMData(contact, idInternal, contact.id!);

    this.form.controls.clientSearch.reset();
    this.masterSearchControl.reset();
    this.searchControl.reset();
  }

  async patchClient(
    contact: DWS.Reservation.ReservationContactDTO,
    crmContact?: DWS.Contact
  ) {
    let isEmailChanged = false,
      index = undefined;
    const reservationContacts = this.form
      .get("reservation")
      ?.get("reservationContacts") as FormArray;
    index = reservationContacts.controls.findIndex(
      (control: any) =>
        control.get("id")?.value === this.reservationContactToEditId
    );
    isEmailChanged =
      reservationContacts.at(index).get("email")!.value !== contact?.email ||
      !!crmContact?.email;
    reservationContacts.at(index).patchValue({
      id: contact?.id || this.reservationContactToEditId,
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      email: contact?.email,
      phoneNumber: contact?.phoneNumber,
      countryIso: contact?.countryIso,
      manualMarketing: contact?.manualMarketing,
    });

    this.contactsNotMatchingAlerts[
      reservationContacts.at(index)?.get("id")?.value
    ] = {};
    if (crmContact) {
      isEmailChanged &&
        this.retrieveCRMData(
          contact,
          this.reservationContactToEditId!,
          crmContact.id!
        );

      if (crmContact.email !== contact.email)
        this.contactsNotMatchingAlerts[
          reservationContacts.at(index)?.get("id")?.value
        ][tr("Email")] = crmContact.email || "-";
      if (
        crmContact.firstName !== contact.firstName &&
        crmContact.firstName?.replace("_", "") !== contact.firstName
      )
        this.contactsNotMatchingAlerts[
          reservationContacts.at(index)?.get("id")?.value
        ][tr("First name")] = crmContact.firstName || "-";
      if (
        crmContact.lastName !== contact.lastName &&
        crmContact.lastName?.replace("_", "") !== contact.lastName
      )
        this.contactsNotMatchingAlerts[
          reservationContacts.at(index)?.get("id")?.value
        ][tr("Last name")] = crmContact.lastName || "-";
      if (crmContact.phone !== contact.phoneNumber)
        this.contactsNotMatchingAlerts[
          reservationContacts.at(index)?.get("id")?.value
        ][tr("Phone")] = crmContact.phone || "-";
      if (crmContact.countryOfResidenceIso !== contact.countryIso)
        this.contactsNotMatchingAlerts[
          reservationContacts.at(index)?.get("id")?.value
        ][tr("Country of Residence")] = crmContact.countryOfResidenceIso || "-";

      if (crmContact.companyName == contact.firstName) {
        delete this.contactsNotMatchingAlerts[
          reservationContacts.at(index)?.get("id")?.value
        ][tr("First name")];
        delete this.contactsNotMatchingAlerts[
          reservationContacts.at(index)?.get("id")?.value
        ][tr("Last name")];
      }
    }

    if (
      Object.keys(
        this.contactsNotMatchingAlerts[
          reservationContacts.at(index)?.get("id")?.value
        ]
      )?.length
    ) {
      await this.updateCRMContactOrDoNothing(crmContact!.id!, contact);
    } else {
      this.contactsNotMatchingAlerts[
        reservationContacts.at(index)?.get("id")?.value
      ] = {};
    }
  }

  retrieveCRMData(
    contact: DWS.Reservation.ReservationContactDTO,
    id: number | string,
    crmId: number
  ) {
    this.statisticsService
      .get(crmId, contact.email, this.companyId)
      .subscribe((statistics) => {
        this.contactsData[id].contactStatistics = statistics;
      });
    this.contactsTagsService
      .list({
        contactId: crmId,
        wineryId: this.companyId,
      })
      .subscribe((tags) => {
        this.contactsData[id].contactTags = tags.content;
      });
    this.contactsService.getByEmail(contact.email!).subscribe((contact) => {
      this.contactsData[id].contactPrivacy = {
        acceptedPrivacyTerms: contact.acceptedPrivacyTerms,
        acceptedMarketing: contact.acceptedMarketing,
        acceptedProfiling: contact.acceptedProfiling,
        manualMarketing: contact.manualMarketing,
        unsubscribed: contact.unsubscribed,
      };
      this.allContactsControls.find((control) => control.get("id")?.value === id)?.patchValue({
        manualMarketing: contact.manualMarketing,
      });
    });
  }

  showClientList() {
    setTimeout(() => this.trigger.openPanel());
  }

  clientAdded(client: Client) {
    this.patchClient({
      ...client,
      firstName: client.firstname,
      lastName: client.lastname,
    });
  }

  editClientClicked(id: number | string) {
    this.reservationContactToEditId = id;
    this.clientSaveCallback = (client: Client) => this.clientAdded(client);
    this.clientSideNav.open();
  }

  experienceChanged() {
    if (!this.experience) return;

    if (this.experienceType == "event") {
      this.experienceEventsAvailabilitiesService
        .showExperienceEvents(this.experience!.id)
        .subscribe((events) => {
          this.eventDates = events.map((e) => e.day + " " + e.time);
        });
    } else {
      this.availabilityServiceDws
        .getAvailabilitiesByExperience(this.experience!.id)
        .subscribe((daysAvailability) => {
          this.availableSlots = daysAvailability;
        });
    }

    if (this.reservation) {
      const reservationDate = this.eventDates.find((value) => {
        const [day, time] = value.split(" ");
        return day === this.reservation?.date && time === this.reservation.time;
      });
      if (reservationDate == null) {
        let newEventDate = `${this.reservation.date} ${this.reservation.time}`;
        this.eventDates.push(newEventDate);
        this.form.get("reservation")!.get("event_date")!.setValue(newEventDate);
      } else {
        this.form
          .get("reservation")!
          .get("event_date")!
          .setValue(reservationDate);
      }
    }
    if (this.reservationToClone) {
      const reservationDate = this.eventDates.find((value) => {
        const [day, time] = value.split(" ");
        return (
          day === this.reservationToClone?.date &&
          time === this.reservationToClone.time
        );
      });
      if (reservationDate == null) {
        let newEventDate = `${this.reservationToClone.date} ${this.reservationToClone.time}`;
        this.eventDates.push(newEventDate);
        this.form.get("reservation")!.get("event_date")!.setValue(newEventDate);
      } else {
        this.form
          .get("reservation")!
          .get("event_date")!
          .setValue(reservationDate);
      }
    }

    if (this.experienceType == "event") {
      this.form
        .get("reservation")
        ?.get("event_date")
        ?.setValidators(Validators.required);
      this.form.get("reservation")?.get("date")?.clearValidators();
      this.form.get("reservation")?.get("time")?.clearValidators();
      if (
        (this.reservationToClone || this.reservation) &&
        this.form.get("event_date")!
      ) {
        this.form.get("reservation")?.get("event_date")?.disable();
      }
    }
    if (this.form && this.form.get("reservation") && this.canEdit) {
      if (this.reservationToClone?.experienceId !== this.experience.id) {
        this.form.patchValue({
          reservation: {
            date: "",
            time: "",
            event_dates: "",
            event_date: "",
            lang: "",
          },
        });
      } else {
        this.form.patchValue({
          reservation: {
            date: this.reservationToClone.date,
            time: this.reservationToClone.time,
            interval: this.experience.details.duration,
            lang: this.reservationToClone.languageIso,
          },
        });
      }

      const r = this.form.get("reservation")!;

      if (!this.reservationToClone) {
        r.get("lang")!.enable();
        r.get("date")!.enable();
        r.get("time")!.enable();
        if (r.get("event_date")!) {
          r.get("event_date")!.enable();
        }
      } else {
        if (!!!!this.reservationToClone.language) {
          r.get("lang")!.disable();
        }
      }

      r.get("total")!.enable();
    }

    this.fillPriceLabels();
    this.fillPriceExtras();

    this.availableLanguages = this.experienceLanguages;
  }

  displayDate(date: string) {
    return _.capitalize(
      moment(date).locale("it").format("dddd, DD MMMM YYYY [h]HH:mm")
    );
  }

  async confirmReservationClicked() {
    var reservationId = this.reservationId!;

    this.form.get("reservation")?.get("discount")?.markAsPristine(); // couldn't find out where is it touched
    if (this.form.pristine && this.clientForm.pristine) {
      const sure = await confirm.yesno(
        tr("Are you sure you want to confirm the reservation?"),
        tr("Yes"),
        tr("Back")
      );
      if (!sure) return;
    } else {
      const r = await confirm.yesno(
        tr(
          "You are about to confirm the reservation without having saved the changes just made. Make sure to save the changes before proceeding with the confirmation. Do you want to save the changes now?"
        ),
        tr("Save and Confirm"),
        tr("Confirm without saving")
      );
      if (r) {
        this.form
          .get("reservation")!
          .get("status")!
          .setValue(ReservationDetail.status(undefined, "confirmed"));
        await this.doSave();
        return;
      }
    }

    this.isSaving(true);

    this.manualReservationListenerService.addRequestIdToListener(
      reservationId,
      reservationId,
      this.reservation!.state!
    );

    this.reservationDetailService.confirmReservationV2(reservationId).subscribe(
      (request) => {
        this.isSaving(false);

        this.closeClick.emit();
      },
      (err) => {
        toast(tr(err));
        this.isSaving(false);
      }
    );
  }

  getDuration(): string {
    var duration = "";
    if (this.experience) {
      duration = this.experience.details.duration;
    }

    const durationPattern = /(\d+d)?\s*(\d+h)?\s*(\d+m)?/;
    const match = duration.match(durationPattern);

    if (match) {
      const days = match[1] && match[1] != "0d" ? match[1].trim() : "";
      const hours = match[2] && match[2] != "0h" ? match[2].trim() : "";
      const minutes = match[3] && match[3] != "0m" ? match[3].trim() : "";

      // Construct the new duration string, omitting zero values
      duration = `${days ? days : ""}${days && hours ? " " : ""}${
        hours ? hours : ""
      }${(days || hours) && minutes ? " " : ""}${
        minutes ? minutes : ""
      }`.trim();
    }

    return duration;
  }

  async rejectReservationClicked() {
    var reservationId = this.reservationId!;
    const sure = await confirm.yesno(
      tr("Are you sure you want to reject the reservation?"),
      tr("Yes"),
      tr("Back")
    );
    if (!sure) return;
    this.isSaving(true);

    this.manualReservationListenerService.addRequestIdToListener(
      reservationId,
      reservationId,
      this.reservation!.state!
    );

    this.reservationDetailService.rejectReservationV2(reservationId).subscribe(
      (request) => {
        this.isSaving(false);

        this.closeClick.emit();
      },
      (err) => {
        console.error(err);
        toast(tr(this.messageError));
        this.isSaving(false);
      }
    );
  }

  async cancelReservationClicked() {
    var reservationId = this.reservationId!;
    const sure = await confirm.yesno(
      tr("Are you sure you want to delete the reservation?"),
      tr("Yes"),
      tr("Back")
    );
    if (!sure) return;
    this.isSaving(true);

    this.manualReservationListenerService.addRequestIdToListener(
      reservationId,
      reservationId,
      this.reservation!.state!
    );

    this.reservationDetailService.cancelReservationV2(reservationId).subscribe(
      (request) => {
        this.isSaving(false);
        this.closeClick.emit();
      },
      (err) => {
        console.error(err);
        toast(tr(this.messageError));
        this.isSaving(false);
      }
    );
  }

  async revokeReservationClicked() {
    var reservationId = this.reservationId!;
    var mex = tr(
      "You are about to revoke a confirmed reservation. Do you want to proceed with the cancellation?"
    );
    if (this.shouldAskForSlotClosing) {
      mex = tr(
        'You are about to revoke a confirmed reservation. Please check if it is necessary to reopen the availability slots in the "Availability Management" section and make the language available again for future customers. Do you want to proceed with the cancellation?'
      );
    }
    const sure = await confirm.yesno(mex, tr("Confirm!"), tr("Cancel"));
    if (!sure) return;
    this.isSaving(true);

    this.manualReservationListenerService.addRequestIdToListener(
      reservationId,
      reservationId,
      this.reservation!.state!
    );

    this.reservationDetailService.revokeReservationV2(reservationId).subscribe(
      (request) => {
        this.isSaving(false);
        this.closeClick.emit();
      },
      (err) => {
        console.error(err);
        toast(tr(this.messageError));
        this.isSaving(false);
      }
    );
  }

  isSaving(start: boolean) {
    if (start) {
      this.saveDisabled = start;
      this.isConfirmingOrRejecting = start;

      toast(tr("Saving..."));
    } else {
      this.saveDisabled = false;
      this.isConfirmingOrRejecting = false;
    }
  }

  returnToGift() {
    this.returnGift.emit();
  }

  monthSelected(month: Moment) {
    this.monthpicker.close();
    const start = month.toDate(),
      end = month.endOf("month").toDate();
    this.loading = true;
    this.experienceAvailabilitiesService
      .get(this.experience!.id, { start, end })
      .subscribe((daysAvailability) => {
        this.availabilities = daysAvailability;
        this.dateAvailableFilter = (d: Moment) => {
          return ExperienceAvailability.isDayAvailable(
            daysAvailability,
            d.toDate(),
            this.company.company_closings
          );
        };
        this.datepicker.startAt = month;
        this.datepicker.open();
      })
      .add(() => (this.loading = false));
  }

  hasClientAssocieted(id: number | string | undefined): boolean {
    if (!id) return false;
    const rc = (
      this.form.get("reservation")?.get("reservationContacts") as FormArray
    ).controls.find((control: any) => control.get("id")?.value === id);
    if (!rc) return false;
    else
      return (
        rc.get("firstName")?.value ||
        rc.get("lastName")?.value ||
        rc.get("email")?.value ||
        rc.get("phoneNumber")?.value ||
        rc.get("countryIso")?.value
      );
  }

  addClientRequested(callback: (client: Client) => void) {
    this.clientSaveCallback = callback;
    this.clientSideNav.open();
  }

  private async updateCRMContactOrDoNothing(
    contactId: number,
    contact: any,
    callback?: Function,
    force: boolean = false
  ) {
    if (
      force ||
      (await confirm.yesno(tr("Do you want to update the data in the CRM?")))
    ) {
      this.contactsService.updateShort(contactId, contact).subscribe(
        () => {
          toast(tr("Resevation contact updated successfully"));
          this.contactsNotMatchingAlerts[
            this.masterContactControl?.get("id")?.value
          ] = {};
          callback && callback();
        },
        () => {
          toast(tr(this.messageError));
        }
      );
    }
  }

  private askForAssociationWithExistingContactAndPatchClient(
    CRMContact: DWS.Contact,
    currentContact: any
  ) {
    this.dlg
      .open(ContactAssociationComponent, {
        data: { contact: CRMContact },
      })
      .afterClosed()
      .subscribe((yesClicked) => {
        if (yesClicked) {
          const reservationContacts = this.form
            .get("reservation")
            ?.get("reservationContacts") as FormArray;
          const index = reservationContacts.controls.findIndex(
            (control: any) =>
              control.get("id")?.value === this.reservationContactToEditId
          );
          reservationContacts
            .at(index)
            .get("crmContactId")!
            .setValue(CRMContact.id);
          this.patchClient(currentContact, CRMContact);
        } else {
          this.patchClient(currentContact);
        }
      });
  }

  clientCloseClicked(
    contact: DWS.Reservation.ReservationContactDTO | undefined
  ) {
    this.clientSideNav.close();
    if (contact === undefined) {
      return;
    }

    if (!this.reservationContactToEditId) {
      const newId = (crypto as any).randomUUID();
      let fg = this.fb.group({
        id: [contact.id || newId],
        name: [""],
        firstName: [""],
        lastName: [""],
        email: [""],
        phoneNumber: [""],
        countryIso: [""],
        contactType: ["MASTER"],
        crmContactId: [""],
        manualMarketing: [false],
      });
      (
        this.form.get("reservation")!.get("reservationContacts") as FormArray
      ).push(fg);
      this.reservationContactToEditId = contact.id || newId;
    }

    this.contactsData[this.reservationContactToEditId!] = {} as any;
    this.contactDetailOpen[this.reservationContactToEditId!] = false;
    this.contactsNotMatchingAlerts[this.reservationContactToEditId!] = {};

    this.clients = [];
    let crmContactId = this.allContactsControls
      .find(
        (control: any) =>
          control.get("id")?.value == this.reservationContactToEditId
      )
      ?.get("crmContactId")?.value;

    const contactControl = this.allContactsControls.find(
      (control: any) =>
        control.get("id")?.value == this.reservationContactToEditId
    );

    if (!!crmContactId || !!contact.email) {
      (!!crmContactId
        ? of(crmContactId)
        : this.contactsService
            .getByEmail(contact.email!)
            .pipe(map((contact) => contact?.id))
      ).subscribe((contactId) => {
        if (contactId) {
          this.contactsService
            .getv2(contactId, this.companyId)
            .subscribe(async (contactInt) => {
              if (
                contactInt &&
                (!crmContactId || crmContactId != contactInt.id)
              ) {
                this.askForAssociationWithExistingContactAndPatchClient(
                  contactInt,
                  contact
                );
              } else if (!contactInt && contactId) {
                this.contactsNotMatchingAlerts[
                  this.masterContactControl?.get("id")?.value
                ] = {};
                this.contactsNotMatchingAlerts[
                  this.masterContactControl?.get("id")?.value
                ][tr("Email")] = contactControl?.get("email")?.value;
                this.updateCRMContactOrDoNothing(
                  contactControl!.get("crmContactId")!.value,
                  contact,
                  this.patchClient.bind(this, contact),
                  true
                );
              } else {
                this.patchClient(contact, contactInt);
              }
            });
        } else {
          if (contact) {
            this.patchClient(contact);
          }
        }
      });
    } else {
      this.patchClient(contact);
    }
  }

  @ViewChild("datepicker", { static: false })
  datepicker!: MatDatepicker<Moment>;
  @ViewChild("monthpicker", { static: false })
  monthpicker!: MatDatepicker<Moment>;

  get secondaryContactsControls() {
    return (
      this.form.get("reservation")?.get("reservationContacts") as FormArray
    )?.controls.filter((rc) => rc.get("contactType")?.value != "MASTER");
  }

  get allContactsControls() {
    return (
      this.form.get("reservation")?.get("reservationContacts") as FormArray
    )?.controls;
  }

  get masterContactControl() {
    return (
      this.form.get("reservation")?.get("reservationContacts") as FormArray
    )?.controls.find((rc) => rc.get("contactType")?.value == "MASTER");
  }

  get sideNavContact() {
    return (
      (
        this.form.get("reservation")?.get("reservationContacts") as FormArray
      )?.controls.find(
        (rc) => rc.get("id")?.value == this.reservationContactToEditId
      )?.value ||
      (
        this.form.get("reservation")?.get("reservationContacts") as FormArray
      )?.controls.find((rc) => rc.get("contactType")?.value == "MASTER")?.value
    );
  }

  addParticipant() {
    let oneParticipantEmpty = this.secondaryContactsControls.reduce(
      (acc, control) => {
        return acc || !this.hasClientAssocieted(control.get("id")?.value);
      },
      false
    );
    if (oneParticipantEmpty) {
      toast(tr("Fill in all the already added participants to add a new one"));
      return;
    }

    const newId = (crypto as any).randomUUID();
    let fg = this.fb.group({
      id: [newId],
      name: [""],
      firstName: [""],
      lastName: [""],
      email: [""],
      phoneNumber: [""],
      countryIso: [""],
      contactType: ["SECONDARY"],
      crmContactId: [""],
      manualMarketing: [false],
    });
    (
      this.form.get("reservation")!.get("reservationContacts") as FormArray
    ).push(fg);
    this.reservationContactToEditId = newId;
  }

  private setContactsArraysWithValues() {
    this.allContactsControls.forEach((control: any) => {
      this.contactsData[control.get("id").value] = {
        contactPrivacy: {},
        contactStatistics: {},
        contactTags: [],
      } as any;
      this.contactDetailOpen[control.get("id").value] = false;
      this.contactsNotMatchingAlerts[control.get("id").value] = {};
    });
    this.allContactsControls.forEach((control: any) => {
      if (!control.get("crmContactId")?.value) return;
      this.contactsService
        .getv2(control.get("crmContactId").value, this.companyId)
        .subscribe((contact) => {
          if (contact) {
            this.contactsData[control.get("id").value].contactPrivacy = {
              acceptedPrivacyTerms: contact.acceptedPrivacyTerms,
              acceptedMarketing: contact.acceptedMarketing,
              acceptedProfiling: contact.acceptedProfiling,
              manualMarketing: contact.manualMarketing,
              unsubscribed: contact.unsubscribed,
            };
            control.patchValue({
              manualMarketing: contact.manualMarketing,
            });

            if (contact.email !== control.value.email)
              this.contactsNotMatchingAlerts[control.get("id")?.value][
                tr("Email")
              ] = contact.email || "-";
            if (
              contact.firstName !== control.value.firstName &&
              contact.firstName?.replace("_", "") !== control.value.firstName
            )
              this.contactsNotMatchingAlerts[control.get("id")?.value][
                tr("First name")
              ] = contact.firstName || "-";
            if (
              contact.lastName !== control.value.lastName &&
              contact.lastName?.replace("_", "") !== control.value.lastName
            )
              this.contactsNotMatchingAlerts[control.get("id")?.value][
                tr("Last name")
              ] = contact.lastName || "-";
            if (contact.phone !== control.value.phoneNumber)
              this.contactsNotMatchingAlerts[control.get("id")?.value][
                tr("Phone")
              ] = contact.phone || "-";
            if (contact.countryOfResidenceIso !== control.value.countryIso)
              this.contactsNotMatchingAlerts[control.get("id")?.value][
                tr("Country of Residence")
              ] = contact.countryOfResidenceIso || "-";

            if (contact.companyName == control.value.firstName) {
              delete this.contactsNotMatchingAlerts[control.get("id")?.value][
                tr("First name")
              ];
              delete this.contactsNotMatchingAlerts[control.get("id")?.value][
                tr("Last name")
              ];
            }
          }
        });
      this.statisticsService
        .get(
          control.get("crmContactId").value,
          control.get("email")?.value,
          this.companyId
        )
        .subscribe((statistics) => {
          this.contactsData[control.get("id").value].contactStatistics =
            statistics;
        });
      this.contactsTagsService
        .list({
          contactId: control.get("crmContactId").value,
          wineryId: this.companyId,
        })
        .subscribe((tags) => {
          this.contactsData[
            control.get("id").value as number | string
          ].contactTags = tags.content;
        });
    });
  }

  private setFormValues() {
    if (this.isTourOperator) {
      this.form
        .get("reservation")!
        .get("manual_origin")!
        .setValue(this.manualOrigins.find((origin) => origin.id === 5));
      this.form.get("reservation")!.get("manual_origin")!.disable();

      const payment1 = this.paymentMethods?.find(
        (pm) => pm.code == "integration"
      );
      this.form.get("reservation")!.get("payment_method")!.setValue(payment1);
      this.form
        .get("reservation")!
        .get("payment2_method")!
        .setValue(payment1?.children?.find((pm) => pm.code == "tour_operator"));
    }

    const r = this.reservation,
      rC = this.reservationToClone,
      e = this.experience;
    if (r && e) {
      this.formPatched = false;
      const visitReason: HostReservationFormOptions.VisitReason | undefined =
        this.availableVisitReasons.find((tag) => tag.id === r.visitReasonId);
      const manualOrigins = this.manualOrigins.find(
        (origin) => origin.id === r.manualOriginId
      );

      const payment_method = this.paymentMethods.find(
        (pm) => pm.id === r.paymentMethodId
      );
      const payment2_method = payment_method?.children?.find(
        (pm) => pm.id === r.payment2MethodId
      );

      var reservationToPatch = {
        reservation: {
          id: r.id, //@ts-ignore
          title: e[`title_${locale().locale}`],
          date: parse(r.date!, "yyyy-MM-dd", new Date()),
          contactId: r.contactId,
          time: r.time,
          interval: e.details.duration,
          groupCount: [0],
          lang: r.language ? r.language.iso : undefined,
          origin: r.origin!,
          visit_reason: r.visitReasonId ? visitReason : undefined,
          refer: "",
          total: ((r.netTotalCents || 0) + Number(r.otherData?.giftCents || 0)) / 100,
          due: r.dueTotalCents ? r.dueTotalCents / 100 : 0,
          paid: r.paid || (r.netTotalCents == 0 && !!r.otherData?.giftCents),
          paidAmout: ((r.paidTotalCents || 0) + Number(r.otherData?.giftCents || 0)) / 100,
          payment_method,
          payment2_method,
          status: ReservationDetail.status(undefined, r.state as any),
          notes: r.notes,
          message: r.message,
          room: r.roomId ? this.getRoom(r.roomId) : undefined,
          employee: r.employeeId ? this.getEmployee(r.employeeId) : undefined,
          manual_origin: r.manualOriginId ? manualOrigins : undefined,
          discount: r.discountTotalCents ? r.discountTotalCents / 100 : 0,
          additional_discount: r.additionalDiscountTotalCents
            ? r.additionalDiscountTotalCents / 100
            : (r.manualDiscountTotalCents || 0) / 100,
          giftAmount: (r.otherData?.giftCents || 0) / 100,
          notify_contact: r.notifyContact,
        } as any,
      };

      if (r.otherData) {
        if (r.otherData.isTourOperator) {
          this.isTourOperator =
            r.otherData.isTourOperator == true ||
            r.otherData.isTourOperator == "true";
        }

        if (r.otherData.isSales) {
          reservationToPatch.reservation.isSales =
            r.otherData.isSales == true || r.otherData.isSales == "true";
        }

        if (r.otherData.checkedIn) {
          reservationToPatch.reservation.checkedIn =
            r.otherData.checkedIn == true || r.otherData.checkedIn == "true";
        }

        if (r.otherData.coupon) {
          reservationToPatch.reservation.coupon_code = r.otherData.coupon;
        }
      }

      this.form.patchValue(reservationToPatch);

      (r.reservationContacts || []).forEach((rc) => {
        let fg = this.fb.group({
          id: [""],
          name: [""],
          firstName: [""],
          lastName: [""],
          email: [""],
          phoneNumber: [""],
          countryIso: [""],
          contactType: [""],
          crmContactId: [""],
          manualMarketing: [false],
        });
        fg.patchValue(rc);
        fg.patchValue({
          crmContactId:
            !rc.otherData?.crmContactId && rc.contactType === "MASTER"
              ? r.contactId
              : rc.otherData?.crmContactId,
        });
        (
          this.form.get("reservation")!.get("reservationContacts") as FormArray
        ).push(fg);
      });

      this.patchFormPriceLabels(r);
      this.patchFormPriceExtras(r);
      this.formPatched = true;
    } else if (rC && e) {
      // CLONE RESERVATION
      this.formPatched = false;

      this.form.patchValue({
        reservation: {
          date: rC.date,
          time: rC.time,
          interval: e.details.duration,
          lang: rC.languageIso,
        },
      });
      this.formPatched = true;
    }

    if (!this.form.get("reservation")!.get("payment2_method")!.value)
      this.selectPayment2MethodIfThereIsOnlyOne();
    this.setContactsArraysWithValues();
    this.setAutomaticTotal();
  }

  selectPayment2MethodIfThereIsOnlyOne() {
    if (
      this.form.get("reservation")!.get("payment_method")!.value?.children
        ?.length === 1
    ) {
      this.form
        .get("reservation")!
        .get("payment2_method")!
        .setValue(
          this.form.get("reservation")!.get("payment_method")!.value.children[0]
        );
    }
  }

  private generateForm(): FormGroup {
    const form = this.fb.group({
      reservation: this.fb.group({
        id: [""],
        title: [""],
        date: ["", Validators.required],
        time: ["", Validators.required],
        contactId: [""],
        event_date: [""],
        interval: [""],
        groupCount: [0],
        lang: ["", Validators.required],
        origin: [""],
        visit_reason: [""],
        visit_reason_name: [""],
        room: [""],
        employee: [""],
        refer: [""],
        total: [0, Validators.min(0)],
        paid: [false],
        manual_payment_method: [],
        payment_method: [],
        payment2_method: [],
        notes: [""],
        message: [""],
        status: ["", Validators.required],
        manual_origin: [],
        additional_discount: [0, Validators.min(0)],
        notify_contact: [!this.isTourOperator],
        experience_price_labels: this.fb.array([]),
        experience_price_extras: this.fb.array([]),
        due: [0, Validators.min(0)],
        paidAmout: [0, Validators.min(0)],
        total_calculated: [0, Validators.min(0)],
        discount: [0, Validators.min(0)],
        giftAmount: [0],
        isSales: [false],
        checkedIn: [false],
        reservationContacts: this.fb.array([]),
        coupon_code: [""],
      }),
      clientSearch: [""],
    });

    this.form = form;
    return form;
  }

  private patchReservationFromForm(): ReservationDTO {
    const r = this.form.get("reservation")!;

    let date = r.get("date")!.value;
    if (!moment.isMoment(date)) date = moment(date);
    let time = r.get("time")!.value;
    if (time.split(":").length < 3) time = time + ":00";
    // OTHER DATA JSON
    var otherData = {} as any;
    if (this.reservation?.optionalData) {
      otherData = this.reservation?.optionalData;
    }

    if (
      r.get("isSales")!.value !== undefined &&
      r.get("isSales")!.value !== null
    ) {
      otherData.isSales = r.get("isSales")!.value;
    }

    if (
      r.get("checkedIn")!.value !== undefined &&
      r.get("checkedIn")!.value !== null
    ) {
      otherData.checkedIn = r.get("checkedIn")!.value;
    }

    if (r.get("paid")!.value !== undefined && r.get("paid")!.value !== null) {
      otherData.paid = r.get("paid")!.value;
    }

    if (r.get("coupon_code")!.value !== undefined && r.get("coupon_code")!.value !== null) {
      otherData.coupon = r.get("coupon_code")!.value;
    }

    if (this.isTourOperator) {
      otherData.isTourOperator = this.isTourOperator;
    }

    var oldState = r.get("status")!.value.type || "confirmed";
    var newState = oldState;
    if (this.isDraft) {
      newState = "draft";
    } else {
      if (oldState == "draft" && !this.isDraft) {
        newState = "confirmed";
      }
    }

    return {
      id: r.get("id")!.value,
      state: newState,
      guestCountTotal: this.getTotalCount(),
      guestCount01: r.get("experience_price_labels")?.value[0]?.quantity || 0,
      experiencePriceLabels:
        this.cleanAndFixLabels(
          r.get("experience_price_labels")!.value,
          "LABEL"
        ) || [],
      experiencePriceExtras:
        this.cleanAndFixLabels(
          r.get("experience_price_extras")!.value,
          "EXTRA"
        ) || [],
      languageIso: r.get("lang")!.value,
      experienceId: this.experience?.id,
      reservationContacts: r
        .get("reservationContacts")
        ?.value?.map((c: any) => {
          if (!c.otherData) c.otherData = {};
          c.otherData.crmContactId = c.crmContactId;
          c.otherData.privacy = { manualMarketing: c.manualMarketing };
          if (c.contactType === "MASTER" && this.isTourOperator) {
            c.otherData.tourOperator = true;
          }
          if (typeof c.id === "string" && c.id.includes("-")) {
            delete c.id;
          } else {
            try {
              c.id = parseInt(c.id);
            } catch {
              delete c.id;
            }
          }
          return c;
        }),
      contactId: this.masterContactControl?.get("crmContactId")?.value,
      date: date.format("yyyy-MM-DD"),
      time: time,
      netTotalCents: (r.get("total")!.value * 100) - Number(this.reservation?.otherData?.giftCents || 0),
      grossTotalCents: (r.get("total")!.value * 100) - Number(this.reservation?.otherData?.giftCents || 0),
      discountTotalCents: r.get("discount")!.value * 100,
      additionalDiscountTotalCents: r.get("additional_discount")!.value * 100,
      manualDiscountTotalCents: r.get("additional_discount")!.value * 100,
      paidTotalCents: (r.get("paidAmout")!.value * 100) - Number(this.reservation?.otherData?.giftCents || 0),
      dueTotalCents: r.get("due")!.value * 100,
      paymentCurrency: this.reservation?.paymentCurrency || "EUR",
      origin: r.get("origin")!.value,
      wineryChannelId: undefined,
      wineryChannelTypeId: undefined,
      wineryId: this.companyId,
      message: this.reservation?.message,
      notes: r.get("notes")!.value,
      notifyContact: r.get("notify_contact")!.value,
      paid: r.get("paid")!.value || false,
      giftId: this.reservation?.giftId,
      roomId: r.get("room")!.value?.id || undefined,
      employeeId: r.get("employee")!.value?.id || undefined,
      paymentMethodId: r.get("payment_method")?.value?.id || undefined,
      payment2MethodId: r.get("payment2_method")?.value?.id || undefined,
      manualOriginId: r.get("manual_origin")?.value?.id || undefined,
      visitReasonId: r.get("visit_reason")!.value?.id,
      paymentId: this.reservation?.paymentId,
      type: this.reservation?.type,
      createdAt: this.reservation?.createdAt,
      updatedAt: this.reservation?.updatedAt,
      optionalData: otherData,
    };
  }

  cleanAndFixLabels(labels: any[], type: "LABEL" | "EXTRA" = "LABEL") {
    let pricesArray =
      this.experience?.prices?.filter((price) => price.priceType === type) ||
      [];
    return labels.map((label) => {
      var obj = {
        ...label,
        price_cents: pricesArray.find((x) => x.position == label.position)
          ?.priceCents,
        price_currency: pricesArray.find((x) => x.position == label.position)
          ?.priceCurrency,
      };

      if (!obj.id || obj.id == "") {
        delete obj.id;
      }

      obj.title_translations = {
        it: this.getLabelOrExtra(label.position, type, "it"),
        en: this.getLabelOrExtra(label.position, type, "en"),
        de: this.getLabelOrExtra(label.position, type, "de"),
      };

      delete obj.updated_at;
      delete obj.created_at;
      obj.experienceId = this.experience?.id;

      return obj;
    });
  }

  setAutomaticTotal() {
    const form = this.form;

    const couponCodeCtrl = form.get("reservation")!.get("coupon_code")!;
    couponCodeCtrl.valueChanges.subscribe((couponCode) => {
      let coupon = this.availableCoupons.find((c) => c.code === couponCode);
      this.couponSelected(coupon);
    });

    const discountCtrl = form.get("reservation")?.get("additional_discount")!;
    discountCtrl.valueChanges.subscribe((discount) => {
      this.updateTotal("additional_discount", discount);
    });

    const couponCtrl = form.get("reservation")?.get("discount")!;
    couponCtrl.valueChanges.subscribe((discount) => {
      this.updateTotal("discount", discount);
    });

    const totalCtrl = form.get("reservation")!.get("total")!;
    totalCtrl.valueChanges.subscribe((total) => {
      this.updateTotal("total", total);
    });

    const dueCtrl = form.get("reservation")!.get("due")!;
    dueCtrl.valueChanges.subscribe((due) => {
      this.updateTotal("due", due);
    });

    const paidCtrl = form.get("reservation")!.get("paidAmout")!;
    paidCtrl.valueChanges.subscribe((paidAmout) => {
      this.updateTotal("paidAmout", paidAmout);
    });

    const priceLabelCtrl = form
      .get("reservation")!
      .get("experience_price_labels")!;
    priceLabelCtrl.valueChanges.subscribe((priceLabel) => {
      this.updateTotal("experience_price_labels", priceLabel);
    });

    const priceExtraCtrl = form
      .get("reservation")!
      .get("experience_price_extras")!;
    priceExtraCtrl.valueChanges.subscribe((priceExtra) => {
      this.updateTotal("experience_price_extras", priceExtra);
    });

    this.updateTotal("total", "", true);

    return form;
  }

  updateTotal(
    changedValue:
      "discount"
      | "additional_discount"
      | "experience_price_labels"
      | "experience_price_extras"
      | "due"
      | "total"
      | "paidAmout",
    newValue: any,
    initial: boolean = false
  ) {
    if (!this.experience) return;

    // check if the value is an array not empty a number and > 0
    if (newValue.length) {
      if (newValue.length == 0) newValue = 0;
    } else {
      if (isNaN(newValue) || newValue <= 0) newValue = 0;
    }

    const form = this.form;

    function getCleanValueFrom(form: FormGroup, fieldName: any) {
      try {
        if (!form.get("reservation")!.get(fieldName)) {
          return 0;
        }
        const value = form.get("reservation")!.get(fieldName)!.value || 0;
        return value;
      } catch (err) {
        //no
      }
      return 0;
    }
    // get the total value
    const total = getCleanValueFrom(form, "total");
    // get the discount value
    const discount = getCleanValueFrom(form, "discount");
    // get the additional discount value
    let additionalDiscount = getCleanValueFrom(form, "additional_discount");
    if (changedValue === "additional_discount") {
      additionalDiscount = newValue;
    }

    // arrays of price labels and price extras
    const priceLabels = this.form
      .get("reservation")!
      .get("experience_price_labels")!.value;
    const priceExtras = this.form
      .get("reservation")!
      .get("experience_price_extras")!.value;
    const totalLabels =
      (priceLabels &&
        priceLabels
          .map(
            (e: { quantity: number; price_cents: number }) =>
              e.quantity * e.price_cents
          )
          .reduce((x: any, y: any) => x + y, 0) / 100) ||
      0;

    const totalExtras =
      (priceExtras &&
        priceExtras
          .map(
            (e: { quantity: number; price_cents: number }) =>
              e.quantity * e.price_cents
          )
          .reduce((x: any, y: any) => x + y, 0) / 100) ||
      0;

    // calculate the total value
    let totalCalc = (((totalLabels as number) + totalExtras) as number) || 0;
    let totalWithDiscountCalc =
      (totalCalc * 100) - ((((discount as number) + additionalDiscount) as number) * 100) || 0;
    let totalWithDiscount = Math.max(0, totalWithDiscountCalc / 100); // multiplying and dividing by 100 to avoid floating point issues
    if (changedValue === "total") {
      totalWithDiscount = newValue;
    }

    form.get("reservation")!.get("total_calculated")!.setValue(totalCalc);

    if (initial) return;

    if (
      changedValue !== "total" &&
      changedValue !== "due" &&
      changedValue !== "paidAmout"
    ) {
      form.get("reservation")!.get("total")!.setValue(totalWithDiscount);
      this.updateDue(totalWithDiscount, changedValue, newValue);
    } else {
      this.updateDue(
        form.get("reservation")!.get("total")!.value,
        changedValue,
        newValue
      );
    }
  }

  updateDue(
    totalCalc: number | 0,
    changedValue:
      "discount"
      | "additional_discount"
      | "experience_price_labels"
      | "experience_price_extras"
      | "due"
      | "total"
      | "paidAmout",
    newValue: any
  ) {
    if (totalCalc == null || totalCalc == undefined || totalCalc < 0) return;
    const form = this.form;
    function getCleanValueFrom(form: FormGroup, fieldName: any) {
      try {
        if (!form.get("reservation")!.get(fieldName)) {
          return 0;
        }
        const value = form.get("reservation")!.get(fieldName)!.value || 0;
        return value;
      } catch (err) {
        //no
      }
      return 0;
    }
    // get the paied value
    let paied = getCleanValueFrom(form, "paidAmout");
    if (changedValue === "paidAmout") {
      paied = newValue;
    }

    const priceLabels = this.form.get("reservation")!.get("experience_price_labels")!.value;
    const priceExtras = this.form.get("reservation")!.get("experience_price_extras")!.value;
    const nLabels = priceLabels?.map((e: { quantity: number }) => e.quantity ).reduce((x: any, y: any) => x + y, 0) / 100 || 0;
    const nExtras = priceExtras?.map((e: { quantity: number }) => e.quantity ).reduce((x: any, y: any) => x + y, 0) / 100 || 0;

    // calculate the due value
    let dueCalc = 0;
    if (changedValue === "due") {
      dueCalc = newValue;
    } else {
      dueCalc = (
        this.reservation?.state == 'rejected' || this.reservation?.state == 'revoked'
          ? 0
          : totalCalc
      ) - paied;
      form.get("reservation")!.get("due")!.setValue(dueCalc);
      if (dueCalc > 0 || (nLabels + nExtras) == 0) {
        form.get("reservation")!.get("paid")!.setValue(false);
      } else {
        form.get("reservation")!.get("paid")!.setValue(true);
      }
    }

    let paidCalc = 0;
    if (changedValue === "paidAmout") {
      paidCalc = newValue;
    } else {
      paidCalc = totalCalc - dueCalc;
      form.get("reservation")!.get("paidAmout")!.setValue(paidCalc);
    }
  }

  couponSelected(coupon: Host.Coupon.Data | undefined) {
    this.form.get("reservation")!.get("discount")!.setValue(coupon
        ? (coupon.discount_cents / 100) || (Math.floor(coupon.discount_percent * this.form.get("reservation")!.get("total_calculated")!.value) / 100)
        : 0,
      { emitEvent: true }
    );
  }

  getInputValue(event: Event) {
    return (event.target as HTMLInputElement).value.replace(" €", "");
  }

  private listenerForFetchAvailability() {
    const r = this.form.get("reservation");
    if (!r) return;
    r?.get("date")
      ?.valueChanges.pipe(debounce(() => timer(500)))
      .subscribe((day) => {
        const experienceId = this.experience?.id;
        const time = r.get("time")?.value;

        if (
          (!this.reservation || this.reservation?.type === "manual") &&
          day &&
          experienceId &&
          time
        ) {
          observe(
            this.experienceAvailabilityService.haveAvailability({
              experienceId,
              time,
              date: day,
            })
          ).subscribe((e) => (this.hasAnyAvailabilityForDateDay = e));
        }
        if (day && experienceId && time) {
          console.log(this.availabilities);
        }
      });

    r?.get("time")
      ?.valueChanges.pipe(debounce(() => timer(500)))
      .subscribe((time) => {
        const experienceId = this.experience?.id;
        const day = r.get("date")?.value;

        if (
          (!this.reservation || this.reservation?.type === "manual") &&
          time &&
          experienceId &&
          day
        ) {
          observe(
            this.experienceAvailabilityService.haveAvailability({
              experienceId,
              time,
              date: day,
            })
          ).subscribe((e) => (this.hasAnyAvailabilityForDateDay = e));
        }

        if (day && experienceId && time) {
          console.log(this.availabilities);
        }
      });

    r?.get("event_date")
      ?.valueChanges.pipe(debounce(() => timer(500)))
      .subscribe((eventDate) => {
        if (eventDate) {
          const date = moment(eventDate).format("YYYY-MM-DD");
          const time = moment(eventDate).format("HH:mm");
          const experienceId = this.experience?.id;

          this.form.get("reservation")?.patchValue({
            date,
            time,
          });

          if (
            (!this.reservation || this.reservation?.type === "manual") &&
            date &&
            time &&
            experienceId
          ) {
            observe(
              this.experienceAvailabilityService.haveAvailability({
                experienceId,
                time,
                date: date,
              })
            ).subscribe((e) => (this.hasAnyAvailabilityForDateDay = e));
          }
        }
      });
  }

  getBackButtonMessage(
    currentState: string | undefined,
    selectedState: string
  ) {
    if (this.isManualReservation) {
      if (this.hasAnyAvailabilityForDateDay && this.shouldAskForSlotClosing) {
        return this.getIgnoreBlockButton();
      }
    } else if (
      currentState === "waiting" &&
      selectedState === "confirmed" &&
      this.shouldAskForSlotClosing
    ) {
      return this.getIgnoreBlockButton();
    }
    return tr("Back");
  }

  isPaid(): boolean {
    return this.reservation?.paid === true;
  }

  capitalize(x: string) {
    return _.capitalize(x);
  }

  openPaymentPage() {
    this.loading = true;
    observe(
      !!this.paymentLinkData
        ? of(
            `${environment.values["stripe-dashboard-url"]}/payments/${this.paymentLinkData?.paymentIntentExternalId}`
          )
        : this.paymentMethodsService.getPaymentPageUrl(this.reservation!.id!)
    ).subscribe((url) => {
      if (url && url.length > 0) {
        window.open(url, "_blank");
      } else {
        toast(tr("No payment data available"));
      }
      this.loading = false;
    });
  }

  formatString(template: string, ...values: any[]): string {
    return template.replace(/{(\d+)}/g, (match, index) => {
      const adjustedIndex = parseInt(index) - 1;
      const value = values[adjustedIndex];
      return value !== undefined ? value : match;
    });
  }

  generateMessage(): string {
    var messageOrig = tr("Contact_USER");
    var res = this.reservation;
    var name = this.reservation?.reservationMasterContact?.name;
    var email = this.reservation?.reservationMasterContact?.email;
    var phone = this.reservation?.reservationMasterContact?.phoneNumber;
    var date = this.reservation?.date;
    const inputDate = new Date(date!);
    // Format the date in "dd-mm-yyyy" format
    const outputDateStr = `${inputDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${inputDate.getFullYear()}`;
    var time = this.reservation?.time;
    var reservationId = this.reservation?.id;
    var cantina = this.companyInt?.name;
    var experience =
      this.experience?.details.titles.find(
        (t) => t.languageIsoCode.toLowerCase() == locale().locale
      )?.text || "";
    return this.formatString(
      messageOrig,
      name,
      outputDateStr,
      time,
      cantina,
      reservationId,
      experience
    );
  }

  generateSubject(): string {
    var messageOrig = tr("Contact_USER_subj");
    var res = this.reservation;
    var name = this.reservation?.reservationMasterContact?.name;
    var email = this.reservation?.reservationMasterContact?.email;
    var phone = this.reservation?.reservationMasterContact?.phoneNumber;
    var reservationId = this.reservation?.id;
    var date = this.reservation?.date;
    const inputDate = new Date(date!);
    // Format the date in "dd-mm-yyyy" format
    const outputDateStr = `${inputDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${inputDate.getFullYear()}`;
    var time = this.reservation?.time;
    var experience =
      this.experience?.details.titles.find(
        (t) => t.languageIsoCode.toLowerCase() == locale().locale
      )?.text || "";
    var cantina = this.companyInt?.name;
    return this.formatString(
      messageOrig,
      name,
      outputDateStr,
      time,
      cantina,
      reservationId,
      experience
    );
  }

  sendWhatsApp() {
    var message = this.generateMessage();
    var phone = this.reservation?.reservationMasterContact?.phoneNumber
      ?.replace("+", "")
      .replace(" ", "");
    const url =
      "https://wa.me/" + phone + "/?text=" + encodeURIComponent(message); // Replace with the URL you want to open
    window.open(url, "_blank");
  }

  sendMailTo() {
    var message = this.generateMessage();
    var subject = this.generateSubject();
    var email = this.reservation?.reservationMasterContact?.email;
    const url =
      "mailto:" +
      email +
      "?subject=" +
      encodeURIComponent(subject) +
      "&body=" +
      encodeURIComponent(message); // Replace with the URL you want to open
    window.open(url, "_blank");
  }

  getTotalCount(): number {
    const r = this.form.get("reservation")!;
    var qty = 0;

    const priceLabels = this.form
      .get("reservation")!
      .get("experience_price_labels")!.value;

    const totalLabels =
      (priceLabels &&
        priceLabels
          .map((e: { quantity: number; price_cents: number }) => e.quantity)
          .reduce((x: any, y: any) => x + y, 0)) ||
      0;

    qty += totalLabels as number;

    return qty;
  }

  toggleTooltip(
    event: MouseEvent,
    isEntering: boolean,
    element: InfoIconHoverableComponent
  ): void {
    if (isEntering) {
      element.onMouseOverInternal(event);
    } else {
      element.onMouseOut(event);
    }
  }

  isTotalWarning() { // multiplying by 100 to avoid floating point problems
    return (
      parseFloat(this.form.get("reservation")?.get("total")?.value) * 100
    ) != Math.max(0,
      (this.form.get("reservation")?.get("total_calculated")?.value * 100)
      - (parseFloat(this.form.get("reservation")?.get("discount")?.value) * 100)
      - (parseFloat(this.form.get("reservation")?.get("additional_discount")?.value || 0) * 100)
      // - (parseFloat(this.form.get("reservation")?.get("giftAmount")?.value || 0) * 100) //already added to total
    );
  }

  additionalDiscountChanged() {
    this.form.get("reservation")?.get("total")?.setValue(
      this.form.get("reservation")?.get("total_calculated")?.value
      - this.form.get("reservation")?.get("discount")?.value
      - this.form.get("reservation")?.get("additional_discount")?.value
      - this.form.get("reservation")?.get("giftAmount")?.value
    );
  }

  reservationDateChanged(event: MatDatepickerInputEvent<Moment>) {
    if (event.value && event.value.toDate() < new Date()) {
      this.form.get("reservation")?.get("notify_contact")?.setValue(false);
    } else {
      this.form.get("reservation")?.get("notify_contact")?.setValue(true);
    }
  }

  getOrigin(origin: any): any {
    if (!!!origin) {
      return "";
    }
    var originFormatted =
      "" +
      origin
        ?.replace("https://", "")
        .replace("http://", "")
        .replace("www.", "")
        .replace(/\:\d+/g, "")
        .toLowerCase();
    if (
      ("" + origin).toLocaleLowerCase().indexOf("manual") > -1 ||
      "Wine Suite".indexOf(origin) > -1
    ) {
      originFormatted = tr("Manual Reservation");
    }
    originFormatted = tr(originFormatted);
    return originFormatted ? originFormatted : origin ? tr(origin) : "";
  }

  async generatePaymentLinkClicked(event: MouseEvent) {
    event.stopPropagation();
    let sure = await confirm.yesno(
      tr("Are you sure you want to generate a payment link for {}?").replace(
        "{}",
        new CurrencyPipe(locale().locale, "EUR").transform(
          (this.reservation?.dueTotalCents || 0) / 100,
          "EUR",
          "symbol"
        )
      ),
      tr("Confirm!"),
      tr("Cancel")
    );
    if (!sure) return;

    this.isCreatingPaymentLink = true;
    this.wsPaymentsService
      .createPaymentLink(
        [
          {
            quantity: 1,
            netTotalCents: this.reservation?.dueTotalCents || 0,
            vatPercent: 0,
            entityType: "reservation",
            entityId: this.reservation?.id,
          },
        ],
        this.reservation?.contactId
      )
      .subscribe(
        ([pl, _]) => {
          this.paymentLinkData = pl;
          this.openPaymentLinkDialog();
          this.isCreatingPaymentLink = false;
        },
        (err) => {
          toast(tr("An error occurred. Try later"));
          this.isCreatingPaymentLink = false;
        }
      );
  }

  openPaymentLinkDialog() {
    this.dlg.open(PaymentLinkDialogComponent, {
      data: {
        reservation: this.reservation,
        experience: this.experience,
        companyInt: this.companyInt,
        paymentLink: this.paymentLinkData,
        isPaid: this.isPaid(),
      },
      width: "600px",
      height: "370px",
    });
  }


  openAvailabilityAutomationDialog() {
    this.dlg.open(AvailabilityAutomationDialogComponent, {
      data: {
        isAvailabilityBlock: this.isAvailabilityBlock,
        isLanguageBlock: this.isLanguageBlock,
        hasEnabledAutomations: this.hasEnabledAutomations
      },
      width: "550px",
      height: "320px",
    }).afterClosed().subscribe(async (result) => {
      const multiple = result?.length > 1;
      if (multiple) {
        const shouldDoAllTheStuff = await confirm.yesno(
          this.getSlotsPrincipalMessage('multiple', result),
          tr("Yes"),
          tr("No")
        );
        if (!shouldDoAllTheStuff) return;
      }
      forkJoin(
        ['openSlots', 'activateOtherLangs', 'closeSlots', 'deactivateOtherLangs'].filter(k => result.includes(k)) // impose manual order
          .map((value) => {
            switch (value) {
              case "closeSlots":
                return this.clickedCloseSlots(multiple);
              case "openSlots":
                return this.clickedOpenSlots(multiple);
              case "deactivateOtherLangs":
                return this.clickedDeactivateOtherLangs(multiple);
              case "activateOtherLangs":
                return this.clickedActivateOtherLangs(multiple);
              default:
                return of(null);
            }
          })
      ).subscribe(()=>this.closeClick.emit());
    });
  }

  async clickedDeactivateOtherLangs(skipConfirmation: boolean = false) {
    const shouldChangeStateSlots = skipConfirmation || await confirm.yesno(
      this.getSlotsPrincipalMessage('languages'),
      this.getConfirmedButtonMessageOpenSlots('languages'),
      this.getConfirmedButtonMessageCloseSlots('languages')
    );
    if (!shouldChangeStateSlots) return;

    this.setDateAndTimeForm();
    if (!this.isLanguageBlock) {
      await this.closeAvailabilities('languages', skipConfirmation);
    }
  }

  async clickedActivateOtherLangs(skipConfirmation: boolean = false) {
    const shouldChangeStateSlots = skipConfirmation || await confirm.yesno(
      this.getSlotsPrincipalMessage('languages'),
      this.getConfirmedButtonMessageOpenSlots('languages'),
      this.getConfirmedButtonMessageCloseSlots('languages')
    );
    if (!shouldChangeStateSlots) return;

    this.setDateAndTimeForm();
    if (this.isLanguageBlock) {
      await this.openAvailabilities('languages', skipConfirmation);
    }
  }

  openCrmContactPage(contactId: number | undefined) {
    if (!contactId) return;
    window.open(this.router.createUrlTree(['/contacts', contactId, this.companyInt?.id, 'contact-info'],).toString(), '_blank');
  }

  filterExperiences(event: Event) {
    if ((event.target as HTMLInputElement).value?.length) {
      this.filteredExperiences = this.availableExperiencesActive?.filter(e =>
        e.details.titles.find(t => t.text.toLowerCase().includes((event.target as HTMLInputElement).value.toLowerCase()))
      );
    } else {
      this.filteredExperiences = this.availableExperiencesActive;
    }
  }

  canEditField(field: string | string[]): boolean {
    var state = this.reservation?.state;
    var type = this.reservation?.type;

    if (!this.reservation) {
      return true;
    }

    if (state === "canceled" || state === "deleted" || state === "rejected") {
      return false;
    }

    if (!this.reservation || state === "draft") {
      return true;
    }
    var permitted = true;

    if (type === "integration") {
      if (
        typeof field === "string" &&
        PERMITTED_FIELDS_FOR_INTEGRATION.includes(field)
      ) {
        // permitted fields
      } else if (
        Array.isArray(field) &&
        field.every((f) => PERMITTED_FIELDS_FOR_INTEGRATION.includes(f))
      ) {
        // permitted fields
      } else {
        return false;
      }
    }

    if (!this.internalCheckPermission(field)) {
      return false;
    }

    // PERMESSI UTENTE

    return permitted;
  }

  /**
   *
   * @param field a field name or a array (if multiple fields all must be permitted)
   * @returns true if permitted, false if at least one is not permitted
   */
  internalCheckPermission(field: string | string[]): boolean {
    if (!field) return false;

    try {
      let permissions: string[][] = [];

      if (Array.isArray(field)) {
        // Collect all permissions for the given fields
        field.forEach((f) => {
          permissions = permissions.concat(
            PERMISSION_MAP_WITH_FIELDS[f] ||
              PERMISSION_MAP_WITH_FIELDS["default_permission"]
          );
        });
      } else if (typeof field === "string") {
        // Get permission directly for string field
        permissions =
          PERMISSION_MAP_WITH_FIELDS[field] ||
          PERMISSION_MAP_WITH_FIELDS["default_permission"];
      } else {
        permissions = PERMISSION_MAP_WITH_FIELDS["default_permission"];
      }

      // Check permissions using AND for each group and OR between groups
      const hasPermission = permissions.some((permissionOR) =>
        permissionOR.every((permissionAND) =>
          this.accountsService.hasPermission(permissionAND)
        )
      );

      return hasPermission;
    } catch (e) {
      console.debug(e);
      return false;
    }
  }

  get cashRegisterSync$() {
    return this.cashRegisterService
      .syncReservation(this.reservation!.wineryId!, this.reservation!.id!);
  }

  get cashRegisterStatus$() {
    return this.cashRegisterService
    .getMetadata(IntegrationEntityTypes.RESERVATION, this.reservation!.id!)
  }

  getResCashRegisterMetadata() {
    this.cashRegisterService
      .isIntegrated()
      .subscribe((x) => {
        this.regCashIntegrated = x;
      });
  }
}

// this map is for the permissions that are to block if not permitted
const PERMISSION_MAP_WITH_FIELDS: { [key: string]: string[][] } = {
  default_permission: [["WRITE", "CALENDAR"], ["WINERY_ADMIN"]],
  date: [["RESCHEDULE_RESERVATION"], ["WINERY_ADMIN"]],
  time: [["RESCHEDULE_RESERVATION"], ["WINERY_ADMIN"]],
  change_status: [["CHANGE_STATUS_RESERVATION"], ["WINERY_ADMIN"]],
};

const PERMITTED_FIELDS_FOR_INTEGRATION = [
  "origin",
  "visit_reason",
  "notes",
  "room",
  "employee",
  "change_status"
];

@Injectable({
  providedIn: "root",
})
class BackgroundService {
  private _busy: boolean = false;
  get busy(): boolean {
    return this._busy;
  }

  constructor(private snackBar: MatSnackBar) {
    console.log("constructing bg service");
  }

  execute<T>(args: { task: Observable<T>; message: string }): Observable<T> {
    const { task, message } = args;
    const toast = this.snackBar.open(message, undefined, {
      verticalPosition: "top",
    });
    return task.pipe(
      tap(() => {
        toast.dismiss();
        this._busy = false;
      }),
      catchError((err) => {
        toast.dismiss();
        this._busy = false;
        return throwError(err);
      })
    );
  }
}